import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { CSDesktopState } from '../../slices';
import { errorParser } from '../errorParser';
import { getUserFolders } from '../../thunks/get/getUserFolders';
import { updateFolderThunk } from '../../thunks/update/updateFolderThunk';
import { addFolderThunk } from '../../thunks/add/addFolderThunk';
import { deleteFolderThunk } from '../../thunks/delete/deleteFolderThunk';

/**
 * Folder cases for the builder
 *
 * @param builder The builder to add the cases to
 */
export default function folderCases(
  builder: ActionReducerMapBuilder<CSDesktopState>,
): ActionReducerMapBuilder<CSDesktopState> {
  builder
    // * Get Folders * //
    .addCase(getUserFolders.pending, (state) => {
      state.loading = true;
    })
    .addCase(getUserFolders.fulfilled, (state, action) => {
      const { status, token, folders, accounts } = action.payload;
      if (status === 'Succeeded') {
        const builtFolders: {
          [key: string]: { [key2: string]: { username: string; password: string } };
        } = {};
        state.token = token;
        folders.forEach((folder: string) => {
          builtFolders[folder] = {};
        });
        accounts.forEach((account: string[]) => {
          builtFolders[account[3]][account[2]] = {
            username: account[0],
            password: account[1],
          };
        });
        state.folders = builtFolders;
      } else {
        errorParser(token, state);
      }
      state.loading = false;
    })
    .addCase(getUserFolders.rejected, (state) => {
      errorParser('', state);
      state.loading = false;
    })
    // * Add Folder * //
    .addCase(addFolderThunk.fulfilled, (state, action) => {
      const { status, token } = action.payload;
      if (status === 'Succeeded') {
        state.token = token;
      } else {
        errorParser(token, state);
      }
    })
    .addCase(addFolderThunk.rejected, (state) => {
      errorParser('', state);
    })
    // * Delete Folder * //
    .addCase(deleteFolderThunk.fulfilled, (state, action) => {
      const { status, token } = action.payload;
      if (status === 'Succeeded') {
        state.token = token;
      } else {
        errorParser(token, state);
      }
    })
    .addCase(deleteFolderThunk.rejected, (state) => {
      errorParser('', state);
    })
    // * Update Folder * //
    .addCase(updateFolderThunk.fulfilled, (state, action) => {
      const { status, token } = action.payload;
      if (status === 'Succeeded') {
        state.token = token;
      } else {
        errorParser(token, state);
      }
    })
    .addCase(updateFolderThunk.rejected, (state) => {
      errorParser('', state);
    });

  return builder;
}
