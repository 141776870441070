import { useAppSelector } from './data/TypedRedux';
import { CSAlert } from './features/alerts/CSAlert';
import { CSDesktop } from './features/desktop/mainDesktop/CSDesktop';
import { LoginDesktop } from './features/login/LoginDesktop';

/**
 * The main application.
 */
function App() {
  const isUserLoggedIn = useAppSelector((state) => state.CSDesktop.isUserLoggedIn);

  return (
    <div className="App h-full w-full">
      <CSAlert />
      {isUserLoggedIn ? <CSDesktop /> : <LoginDesktop />}
    </div>
  );
}

export default App;
