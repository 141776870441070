import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../state/store';

// Use throughout the app instead of plain `useDispatch` and `useSelector`
/**
 * Typed useDispatch hook
 *
 * @returns Typed dispatch
 */
export function useAppDispatch() {
  return useDispatch<AppDispatch>();
}

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
