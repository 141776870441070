import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiConstants } from '../../../data/constants';

export interface IGetEmail {
  token: string;
}

export const getEmailThunk = createAsyncThunk(
  'CSDesktop/getEmailThunk',
  async (user: IGetEmail) => {
    const response = await fetch(apiConstants.apiUrl + '/getemail', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: user.token,
      },
    });
    const data = await response.json();
    return data;
  },
);
