import { TextField, Box } from '@mui/material';
import EyeHidden from '../../../../../assets/EyeHidden.png';
import EyeVisible from '../../../../../assets/EyeVisible.png';

/**
 * The edit account inputs.
 *
 * @param props The properties of the edit account input.
 * @param props.username The username.
 * @param props.newUserRef The new user ref.
 * @param props.oldPassRef The old pass ref.
 * @param props.newPassRef The new pass ref.
 * @param props.retypePassRef The retype pass ref.
 * @param props.showPassword The show password.
 * @param props.setShowPassword The show password setter.
 */
export function EditAccountInput(props: IEditAccountInputProps) {
  return (
    <>
      <TextField
        label="New Username"
        variant="filled"
        type="text"
        defaultValue={props.username}
        inputRef={props.newUserRef}
        size="small"
        className="rounded-full"
      />
      <Box className="relative w-max h-max flex items-center gap-2 ml-6">
        <TextField
          label="Old Password"
          variant="filled"
          type="password"
          inputRef={props.oldPassRef}
          size="small"
          className="rounded-full"
        />
        <img
          src={props.showPassword.old ? EyeVisible : EyeHidden}
          alt="Show Password icon"
          className="cursor-pointer w-4 h-4"
          style={{
            bottom: '300px',
          }}
          onClick={() => {
            if (props.oldPassRef.current) {
              props.oldPassRef.current.type = props.showPassword.old ? 'password' : 'text';
              props.setShowPassword({ ...props.showPassword, old: !props.showPassword.old });
            }
          }}
        />
      </Box>
      <Box className="relative w-max h-max flex items-center gap-2 ml-6">
        <TextField
          label="New Password"
          variant="filled"
          type="password"
          inputRef={props.newPassRef}
          size="small"
          className="rounded-full"
        />
        <img
          src={props.showPassword.new ? EyeVisible : EyeHidden}
          alt="Show Password icon"
          className="cursor-pointer w-4 h-4"
          style={{
            bottom: '235px',
          }}
          onClick={() => {
            if (props.newPassRef.current) {
              props.newPassRef.current.type = props.showPassword.new ? 'password' : 'text';
              props.setShowPassword({ ...props.showPassword, new: !props.showPassword.new });
            }
          }}
        />
      </Box>
      <Box className="relative w-max h-max flex items-center gap-2 ml-6">
        <TextField
          label="Re-type New Password"
          variant="filled"
          type="password"
          inputRef={props.retypePassRef}
          size="small"
          className="rounded-full"
        />
        <img
          src={props.showPassword.retype ? EyeVisible : EyeHidden}
          alt="Show Password icon"
          className="cursor-pointer w-4 h-4"
          style={{
            bottom: '170px',
          }}
          onClick={() => {
            if (props.retypePassRef.current) {
              props.retypePassRef.current.type = props.showPassword.retype ? 'password' : 'text';
              props.setShowPassword({ ...props.showPassword, retype: !props.showPassword.retype });
            }
          }}
        />
      </Box>
    </>
  );
}

type IShowPassword = { old: boolean; new: boolean; retype: boolean };

interface IEditAccountInputProps {
  username: string;
  newUserRef: React.RefObject<HTMLInputElement>;
  oldPassRef: React.RefObject<HTMLInputElement>;
  newPassRef: React.RefObject<HTMLInputElement>;
  retypePassRef: React.RefObject<HTMLInputElement>;
  showPassword: IShowPassword;
  setShowPassword: React.Dispatch<React.SetStateAction<IShowPassword>>;
}
