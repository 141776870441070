import { MenuContainer } from './MenuContainer';
import { ItemsGrid } from './ItemsGrid';
import { Box } from '@mui/material';
import cheddarSafeLogo from '../../../assets/cheddarSafeRevisedLogo.png';
import { DesktopSideBar } from './DesktopSideBar';
import { CSToolBar } from '../../toolbar/CSToolbar';
import { FolderFile } from './FolderFile';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../data/TypedRedux';
import {
  getAscendingSort,
  getCurrentPage,
  getFolders,
  getIsLoading,
  getSelectedFolder,
} from '../../../state/slices';
import { setCurrentPage } from '../../../state/slices';
import { appConstants } from '../../../data/constants';
import RedoIcon from '../../../assets/RedoIcon.png';
import UndoIcon from '../../../assets/UndoIcon.png';

const styles = {
  CSDesktop: {
    backgroundImage: 'linear-gradient(rgba(86, 38, 131,0.95), rgba(97, 61, 193,0.75))',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    color: 'rgba(255,255,255,0.75)',
  },
  logo: {
    width: '250px',
    height: '250px',
  },
};

const sideBarWidth = 50;
const toolBarHeight = appConstants.toolbarHeight;
const fileWidth = 75;
const fileHeight = 75;
const fileGap = 20;
const paddingTop = 40;
const paddingAround = 20;
const numOfButtons = 2;
let itemsPerColumn =
  Math.floor(
    (window.innerHeight - paddingTop - fileHeight - paddingAround) / (fileHeight + fileGap),
  ) + 1;
let itemsPerRow =
  Math.floor(
    (window.innerWidth - sideBarWidth - fileWidth - paddingAround * 2) / (fileWidth + fileGap),
  ) + 1;

/**
 * The desktop of the Cheddar Safe application.
 *
 * @returns The JSX Element of the Cheddar Safe application.
 */
export function CSDesktop(): JSX.Element {
  const dispatch = useAppDispatch();

  const [items, setItems] = useState(() => null as unknown as JSX.Element[]);

  const selectedFolder = useAppSelector(getSelectedFolder);

  const currentPage = useAppSelector(getCurrentPage);

  const folderArray = selectedFolder
    ? useAppSelector(getFolders)[selectedFolder]
    : useAppSelector(getFolders);

  const ascendingSort = useAppSelector(getAscendingSort);

  const sortedFolders = ascendingSort
    ? Object.keys(folderArray).sort()
    : Object.keys(folderArray).sort().reverse();

  const isLoading = useAppSelector(getIsLoading);

  const sortingBlocker = useRef(0);
  const pageCalculationBlocker = useRef(false);

  /**
   * Gets the items to be displayed on the desktop.
   *
   * @param index The index of the item
   * @param totalItemsPerPage The total number of items per page
   * @param itemsPerRow The number of items per row
   * @returns Returns the JSX Element of the item
   */
  function getItems(index: number, totalItemsPerPage: number, itemsPerRow: number): JSX.Element {
    let element: JSX.Element = null as unknown as JSX.Element;

    // Places the next button on the page
    if (index === totalItemsPerPage * (currentPage + 1) + 1) {
      element =
        index <= sortedFolders.length ? (
          <Box
            width={fileWidth + 'px'}
            height={fileHeight + 'px'}
            onClick={() => dispatch(setCurrentPage(currentPage + 1))}
            key={index}
            className="cursor-pointer"
          >
            <img src={RedoIcon} alt="Next page icon" />
          </Box>
        ) : (
          <Box width={fileWidth + 'px'} height={fileHeight + 'px'} key={index} />
        );
      return element;
    }

    // Places the back button on the page
    else if (index >= totalItemsPerPage * (currentPage + 1) - itemsPerRow + 2) {
      if (index === totalItemsPerPage * (currentPage + 1) - itemsPerRow + 2) {
        element =
          currentPage !== 0 ? (
            <Box
              width={fileWidth + 'px'}
              height={fileHeight + 'px'}
              className="cursor-pointer"
              onClick={() => dispatch(setCurrentPage(currentPage - 1))}
              key={index}
            >
              <img src={UndoIcon} alt="Back page icon" />
            </Box>
          ) : (
            <Box width={fileWidth + 'px'} height={fileHeight + 'px'} key={index} />
          );
        return element;
      } else {
        if (sortedFolders[index - 1]) {
          element = (
            <FolderFile
              fileWidth={fileWidth}
              fileHeight={fileHeight}
              fileName={sortedFolders[index - 1]}
              key={index.toString()}
            />
          );
          return element;
        }
      }
    }

    // If out of bounds, return an empty box
    if (!sortedFolders[index]) {
      element = (
        <Box width={fileWidth + 'px'} height={fileHeight + 'px'} key={index} fontSize="2rem" />
      );
    }

    return element !== null ? (
      element
    ) : (
      <FolderFile
        fileWidth={fileWidth}
        fileHeight={fileHeight}
        fileName={sortedFolders[index]}
        key={index}
      />
    );
  }

  /**
   * Calculates the number of items per page.
   */
  function calculateItems() {
    const indexes: number[] = [];

    itemsPerColumn =
      Math.floor(
        (window.innerHeight - paddingTop - fileHeight - paddingAround) / (fileHeight + fileGap),
      ) + 1;

    itemsPerRow =
      Math.floor(
        (window.innerWidth - sideBarWidth - fileWidth - paddingAround * 2) / (fileWidth + fileGap),
      ) + 1;

    const totalItemsPerPage = itemsPerRow * itemsPerColumn - numOfButtons;

    // If the current page is out of bounds, set it to the last page
    let currentPageLocal = currentPage.valueOf();
    while (currentPageLocal * totalItemsPerPage >= sortedFolders.length) {
      currentPageLocal--;
    }
    dispatch(setCurrentPage(currentPageLocal));

    // Indexes of the files to be displayed
    const startIndex = totalItemsPerPage * currentPage;
    const endIndex = totalItemsPerPage * (currentPage + 1) + numOfButtons;

    // Add the files to the array
    for (let i = startIndex; i < endIndex; i++) {
      indexes[i] = i;
    }

    const items = indexes.map((index) => getItems(index, totalItemsPerPage, itemsPerRow));

    setItems(() => items);
  }

  useEffect(() => {
    if (!pageCalculationBlocker.current) {
      pageCalculationBlocker.current = true;
      return;
    }
    calculateItems();

    window.addEventListener('resize', calculateItems);

    return () => {
      window.removeEventListener('resize', calculateItems);
    };
  }, [currentPage, folderArray]);

  useEffect(() => {
    // * Prevents the useEffect from running on the first render
    if (sortingBlocker.current < 2) {
      sortingBlocker.current++;
      return;
    }
    calculateItems();
  }, [ascendingSort]);

  return (
    <>
      <CSToolBar toolBarHeight={toolBarHeight} />
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
      >
        <Box sx={styles.CSDesktop} />
        <img
          style={styles.logo}
          src={cheddarSafeLogo}
          alt="CheddarSafe Logo"
          className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-3/4 -z-10"
          loading="lazy"
        />
        <DesktopSideBar sideBarWidth={sideBarWidth} />
        <ItemsGrid
          itemsPerRow={itemsPerRow}
          paddingTop={paddingTop}
          paddingAround={paddingAround}
          sideBarWidth={sideBarWidth}
          isLoading={isLoading}
          items={items}
        />
        <MenuContainer />
      </Box>
    </>
  );
}
