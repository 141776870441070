import { Box, Modal } from '@mui/material';

const styles = {
  '@keyframes spin': {
    '0%': { transform: 'translateY(-50%) rotate(0deg)' },
    '100%': { transform: 'translateY(-50%) rotate(360deg)' },
  },
};

/**
 * The loading screen.
 */
export function LoadingScreen() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100%', width: '100%' }}
    >
      <Modal
        open
        className="flex justify-center items-center"
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
      >
        <Box
          className="border-8 border-transparent border-t-purple-600 w-40 h-40 -translate-y-1/2 rounded-full"
          sx={{ ...styles, animation: 'spin 1s ease-in-out infinite' }}
        ></Box>
      </Modal>
    </Box>
  );
}
