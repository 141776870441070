type Changelog = {
  version: string;
  date: string;
  description: string;
  changes: string[];
}[];

export const changelog: Changelog = [
  {
    version: 'v1.0.0',
    date: '07-12-2023',
    description: `
      This is the initial realase of the app!
      It has all the basic features and will be 
      updated in the future with new improvements and upgrades.`,
    changes: ['Initial release', 'Basic features'],
  },
  {
    version: 'v1.0.1',
    date: '07-13-2023',
    description: `
      This is the first update of the app! A few small changes 
      have been made and a couple of Quality of Life improvements
      for both the user and the developer.`,
    changes: [
      'Added about section to the settings.',
      'Added version number',
      'Added version date',
      'Minor backend changes',
    ],
  },
  {
    version: 'v1.0.2',
    date: '07-14-2023',
    description: `
          Font Update! The old fonts were basic and boring. So, I decided to
          go through and update the fonts to include the Ubuntu font family.
          Is subject to change in the future but for now, it's a nice change.
          Also changed the color of the icon texts.
          Finally, the changelog page has been added to the settings page.`,
    changes: [
      'Updated various fonts to Ubuntu.',
      'Small text style changes',
      'Added the Changelog page to the Settings.',
    ],
  },
  {
    version: 'v1.0.3',
    date: '11-20-2023',
    description: `
          UI Update! The old UI was in need of becoming uniform and more
          appealing. As such, I have updated the menu UIs to be consistent
          with each other. Another feature added was custom alerts. Now, when
          a success message or an error message appear, they will be styled
          differently. This will help the user know what type of message they
          are receiving.`,
    changes: [
      'Updated the UI of the menu pages.',
      'Added custom alerts for success and error messages.',
    ],
  },
];
