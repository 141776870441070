import { AccountInfoModal } from '../menus/accountInfoMenu/AccountInfoModal';
import { ContextMenu } from '../menus/ContextMenu';
import { CreationModal } from '../menus/CreationModal';
import { SearchModal } from '../menus/SearchModal';
import { SettingsMenu } from '../menus/SettingsMenu';
import { UserMenu } from '../menus/UserMenu';

/**
 * The container for all of the menus in the Cheddar Safe application.
 */
export function MenuContainer() {
  return (
    <>
      <CreationModal />
      <AccountInfoModal />
      <SettingsMenu />
      <ContextMenu />
      <SearchModal />
      <UserMenu />
    </>
  );
}
