import { Box, ClickAwayListener } from '@mui/material';
import FolderIcon from '../../../assets/FolderIcon.png';
import AccountIcon from '../../../assets/AccountIcon.png';
import { useAppDispatch, useAppSelector } from '../../../data/TypedRedux';
import {
  getSelectedFolder,
  setAccountInfoOpen,
  setContextMenuOpen,
  setContextedItem,
  setSelectedFolder,
} from '../../../state/slices';
import { useState } from 'react';
import { userActiveThunk } from '../../../state/thunks/userActiveThunk';
import { appConstants } from '../../../data/constants';

/**
 * The Folder File.
 *
 * @param props The properties of the folder file.
 * @param props.fileName The name of the folder file.
 * @param props.fileWidth The width of the folder file.
 * @param props.fileHeight The height of the folder file.
 */
export function FolderFile(props: IFolderFileProps): JSX.Element {
  const dispatch = useAppDispatch();
  const selectedFolder = useAppSelector(getSelectedFolder);

  const [selected, setSelected] = useState(false);

  const token = localStorage.getItem('Token');

  /**
   * Enters the folder.
   */
  async function enterFolder() {
    dispatch(userActiveThunk({ token: token === null ? '' : token }))
      .unwrap()
      .then((result) => {
        if (result.status === 'Succeeded') {
          setSelected(false);
          dispatch(setSelectedFolder(props.fileName));
          dispatch(setContextedItem(''));
        }
      });
  }

  /**
   * Enters the account info page.
   */
  async function enterAccount() {
    dispatch(userActiveThunk({ token: token === null ? '' : token }))
      .unwrap()
      .then((result) => {
        if (result.status === 'Succeeded') {
          setSelected(false);
          dispatch(setAccountInfoOpen(true));
        }
      });
  }

  /**
   * Sets the selected folder to the current folder and enters the folder.
   */
  function onClick() {
    setSelected(true);
    dispatch(setContextedItem(props.fileName));
    selected ? (selectedFolder ? enterAccount() : enterFolder()) : null;
  }

  /**
   * Sets the context menu to open and sets the contexted item to the current folder.
   *
   * @param e The mouse event.
   */
  function onRightClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.preventDefault();
    dispatch(setContextMenuOpen(true));
    dispatch(setContextedItem(props.fileName));
  }

  return (
    <>
      <ClickAwayListener onClickAway={() => setSelected(false)}>
        <Box
          width={props.fileWidth + 'px'}
          height={props.fileHeight + 'px'}
          alignItems="center"
          justifyContent="start"
          display="flex"
          flexDirection="column"
          onClick={onClick}
          bgcolor={selected ? 'rgba(150, 150, 220, 0.7)' : 'transparent'}
          borderRadius="0.5rem"
          onContextMenu={onRightClick}
        >
          {selectedFolder ? (
            <img
              src={AccountIcon}
              alt="Folder Icon"
              width="50%"
              height="50%"
              style={{ marginBottom: '0.3rem', marginTop: '0.2rem', userSelect: 'none' }}
            />
          ) : (
            <img
              src={FolderIcon}
              alt="Folder Icon"
              width="60%"
              height="60%"
              style={{
                userSelect: 'none',
              }}
            />
          )}
          <div
            style={{
              overflow: 'hidden',
              overflowWrap: 'break-word',
              width: '100%',
              textAlign: 'center',
              fontSize: '0.8rem',
              marginTop: '-0.4rem',
              cursor: 'default',
              fontFamily: appConstants.toolbarFont,
              color: 'rgb(190, 190, 190)',
              textShadow: '1px 1px 5px black',
              userSelect: 'none',
            }}
          >
            {props.fileName}
          </div>
        </Box>
      </ClickAwayListener>
    </>
  );
}

export interface IFolderFileProps {
  fileWidth: number;
  fileHeight: number;
  fileName: string;
}
