import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiConstants } from '../../../data/constants';

export interface IAccounts {
  token: string;
  username: string;
  password: string;
  accountid: string;
  folder: string;
}

export const addAccountThunk = createAsyncThunk(
  'CSDesktop/addAccountThunk',
  async (props: IAccounts) => {
    const response = await fetch(apiConstants.apiUrl + '/addaccount', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: props.token,
      },
      body: JSON.stringify({ ...props, token: '' }),
    });
    const data = await response.json();
    return data;
  },
);
