import { InfoInputs } from './InfoInputs';
import { InfoHeader } from './InfoHeader';
import { Box, Modal } from '@mui/material';
import {
  getAccountInfoOpen,
  getContextedItem,
  getFolders,
  getSelectedFolder,
  setAccountInfoOpen,
  setFolders,
} from '../../../../state/slices';
import { useAppDispatch, useAppSelector } from '../../../../data/TypedRedux';
import { useState } from 'react';
import { updateAccountThunk } from '../../../../state/thunks/update/updateAccountThunk';
import { addCSAlert } from '../../../../data/addCSAlert';

/**
 * The account info modal.
 */
export function AccountInfoModal() {
  const [editMode, setEditMode] = useState(false);

  const dispatch = useAppDispatch();

  const accountInfoOpen = useAppSelector(getAccountInfoOpen);

  const allFolders = useAppSelector(getFolders);
  const selectedFolder = useAppSelector(getSelectedFolder);
  const contextedItem = useAppSelector(getContextedItem);
  const accountInfo =
    allFolders && selectedFolder && contextedItem
      ? allFolders[selectedFolder][contextedItem]
      : { username: '', password: '' };

  const [localUsername, setLocalUsername] = useState('');
  const [localPassword, setLocalPassword] = useState('');
  const [localAccountId, setLocalAccountId] = useState('');

  const nums = [1, 2, 3, 4, 5, 6, 7, 8];

  /**
   * Closes the modal.
   */
  const closeModal = () => {
    dispatch(setAccountInfoOpen(false));
    setEditMode(false);
  };

  /**
   * Handles the change of the edit mode.
   */
  const handleChangeEditMode = () => {
    if (editMode) {
      if (
        localAccountId !== contextedItem ||
        localUsername !== accountInfo.username ||
        localPassword !== accountInfo.password
      ) {
        if (localAccountId && localUsername && localPassword) {
          dispatch(
            updateAccountThunk({
              token: localStorage.getItem('Token') || '',
              old_account: {
                accountid: contextedItem,
                username: accountInfo.username,
                password: accountInfo.password,
                folder: selectedFolder,
              },
              new_account: {
                username: localUsername,
                password: localPassword,
                accountid: localAccountId,
                folder: selectedFolder,
              },
            }),
          )
            .unwrap()
            .then((result) => {
              if (result.status === 'Succeeded') {
                const newFolder = { ...allFolders[selectedFolder] };
                delete newFolder[contextedItem];
                newFolder[localAccountId] = {
                  username: localUsername,
                  password: localPassword,
                };
                dispatch(setFolders({ ...allFolders, [selectedFolder]: newFolder }));
                setLocalUsername('');
                setLocalPassword('');
                setLocalAccountId('');
                setEditMode(false);
                closeModal();
              }
            });
        } else {
          addCSAlert('Error, missing data.', 'error');
        }
      } else {
        setEditMode(false);
      }
    } else {
      setLocalUsername(accountInfo.username);
      setLocalPassword(accountInfo.password);
      setLocalAccountId(contextedItem);
    }
    setEditMode(!editMode);
  };

  return (
    <Modal
      open={accountInfoOpen}
      onClose={closeModal}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      <Box
        className="w-96 h-96 relative top-28 left-1/2 -translate-x-1/2 flex flex-col items-start
        bg-purple-50 gap-5 scale-125 rounded-md overflow-hidden pt-9"
      >
        <InfoHeader {...{ setEditMode, editMode, handleChangeEditMode }} />
        <Box
          className="bg-gray-300 h-full w-5 z-10 absolute -mt-1 px-1 flex flex-col gap-5"
          paddingTop="2px"
        >
          {nums.map((num) => (
            <div key={num}>{num}</div>
          ))}
        </Box>
        <InfoInputs
          {...{
            accountInfo,
            contextedItem,
            editMode,
            localAccountId,
            localPassword,
            localUsername,
            setLocalAccountId,
            setLocalPassword,
            setLocalUsername,
          }}
        />
      </Box>
    </Modal>
  );
}
