import { Typography } from '@mui/material';
import { useState } from 'react';
import EyeHidden from '../../../../assets/EyeHidden.png';
import EyeVisible from '../../../../assets/EyeVisible.png';

/**
 * The Info Inputs for the account info menu.
 *
 * @param props The properties of the Info Inputs.
 * @param props.accountInfo The account info.
 * @param props.contextedItem The contexted item.
 * @param props.editMode Whether the inputs are in edit mode.
 * @param props.localAccountId The local account id.
 * @param props.localUsername The local username.
 * @param props.localPassword The local password.
 * @param props.setLocalAccountId The local account id setter.
 * @param props.setLocalUsername The local username setter.
 * @param props.setLocalPassword The local password setter.
 */
export function InfoInputs(props: IInfoInputsProps) {
  const [usernameCopied, setUsernameCopied] = useState(false);
  const [passwordCopied, setPasswordCopied] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  /**
   * Copies the given string to the clipboard.
   *
   * @param stringToCopy The string to copy.
   */
  function copyToClipboard(stringToCopy: keyof typeof props.accountInfo) {
    navigator.clipboard.writeText(props.accountInfo[stringToCopy]);
    if (stringToCopy === 'username') setUsernameCopied(true);
    else setPasswordCopied(true);
    setTimeout(() => {
      setUsernameCopied(false);
      setPasswordCopied(false);
    }, 2000);
  }

  /**
   * Gets the account username.
   */
  function getAccountUsername() {
    try {
      return props.accountInfo.username;
    } catch (error) {
      return '';
    }
  }

  return (
    <>
      {props.editMode ? (
        <input
          className="ml-6 pl-1 rounded-md bg-purple-50 underline"
          value={props.localAccountId}
          onChange={(e) => props.setLocalAccountId(e.target.value)}
          style={{
            height: '24px',
            paddingBottom: '3px',
            width: '92.5%',
          }}
        />
      ) : (
        <Typography className="pl-7 underline font-bold">{props.contextedItem}</Typography>
      )}
      <Typography className="pl-7">
        <br />
      </Typography>
      <Typography className="pl-7 underline">Username:</Typography>
      {props.editMode ? (
        <input
          className="ml-6 rounded-md bg-purple-50 pl-1"
          value={props.localUsername}
          onChange={(e) => props.setLocalUsername(e.target.value)}
          style={{
            height: '24px',
            paddingBottom: '3px',
            width: '92.5%',
          }}
        />
      ) : (
        <Typography className="pl-7 cursor-pointer" onClick={() => copyToClipboard('username')}>
          {getAccountUsername()}
        </Typography>
      )}
      <Typography className="pl-7 text-blue-600">{usernameCopied ? 'Copied!' : <br />}</Typography>
      <Typography className="pl-7 underline">Password:</Typography>
      {props.editMode ? (
        <input
          className="ml-6 pl-1 rounded-md bg-purple-50"
          value={props.localPassword.toString()}
          onChange={(e) => props.setLocalPassword(e.target.value)}
          style={{
            height: '24px',
            paddingBottom: '3px',
            width: '92.5%',
          }}
        />
      ) : (
        <>
          <img
            src={showPassword ? EyeVisible : EyeHidden}
            alt="Show Password Icon"
            className="absolute w-3 h-3 bottom-28 left-28 cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          />
          <Typography className="pl-7 cursor-pointer" onClick={() => copyToClipboard('password')}>
            {showPassword ? props.accountInfo.password : '************'}
          </Typography>
        </>
      )}
      <Typography className="pl-7 text-blue-600">{passwordCopied ? 'Copied!' : <br />}</Typography>
    </>
  );
}

interface IInfoInputsProps {
  accountInfo: {
    username: string;
    password: string;
  };
  contextedItem: string;
  editMode: boolean;
  localAccountId: string;
  localUsername: string;
  localPassword: string;
  setLocalAccountId: React.Dispatch<React.SetStateAction<string>>;
  setLocalUsername: React.Dispatch<React.SetStateAction<string>>;
  setLocalPassword: React.Dispatch<React.SetStateAction<string>>;
}
