import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiConstants } from '../../../data/constants';

export interface IFolders {
  token: string;
  folderName: string;
}

export const addFolderThunk = createAsyncThunk(
  'CSDesktop/addFolderThunk',
  async (props: IFolders) => {
    const response = await fetch(apiConstants.apiUrl + '/addfolder', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: props.token,
      },
      body: JSON.stringify({ old_name: '', new_name: props.folderName }),
    });
    const data = await response.json();
    return data;
  },
);
