import { ArrowRight } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { appConstants } from '../../../../../data/constants';

/**
 * The version changes.
 *
 * @param props The properties of the version changes.
 * @param props.version The version of the changes.
 * @param props.date The date of the changes.
 * @param props.description The description of the changes.
 * @param props.changes The changes.
 */
export function VersionChanges(props: VersionChangesProps) {
  const [isShowing, setIsShowing] = useState(false);

  return (
    <Box className="flex flex-col justify-start p-1 w-full">
      <Box
        className="flex items-center cursor-pointer w-max"
        onClick={() => setIsShowing(!isShowing)}
      >
        <Typography fontSize="1.25rem" fontFamily={appConstants.toolbarFont} className="underline">
          {props.version}
        </Typography>
        <ArrowRight
          className="-ml-1"
          sx={{ transition: 'all 0.1s linear', transform: isShowing ? 'rotate(90deg)' : 'none' }}
        />
      </Box>
      <Box
        className="flex overflow-hidden transition-all flex-col mt-2 gap-1"
        height={isShowing ? 'max-content' : '0px'}
      >
        <Box
          className="grid overflow-hidden transition-all"
          gridTemplateRows={isShowing ? 'repeat(1, 1fr)' : 'repeat(1, 0fr)'}
          height={isShowing ? 'max-content' : '0px'}
        >
          <p className="min-h-0">
            <p className="font-bold">Release Date:</p>
            <p className="underline">{props.date}</p>
            <br />
            <p className="font-bold">Description:</p>
            {props.description}
            <br />
            <br />
            <p className="font-bold">Changes:</p>
          </p>
        </Box>
        <Box
          className="grid overflow-hidden transition-all delay-75 gap-2"
          gridTemplateRows={isShowing ? 'repeat(4, 1fr)' : 'repeat(4, 0fr)'}
          height={isShowing ? 'max-content' : '0px'}
        >
          {props.changes?.map((change, index) => (
            <li key={index} className="min-h-0">
              {change}
            </li>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

interface VersionChangesProps {
  version: string;
  date: string;
  description: string;
  changes: string[];
}
