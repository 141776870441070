import { Box, ClickAwayListener } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  getContextMenuOpen,
  getContextedItem,
  getSelectedFolder,
  setContextMenuOpen,
} from '../../../state/slices';
import { useAppDispatch, useAppSelector } from '../../../data/TypedRedux';
import { RenameModal } from './contextMenus/RenameModal';
import { MoveModal } from './contextMenus/MoveModal';
import { DeleteModal } from './contextMenus/DeleteModal';

/**
 * The context menu.
 */
export function ContextMenu() {
  const [mouseCoordinates, setMouseCoordinates] = useState({ x: 0, y: 0 });
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [moveModalOpen, setMoveModalOpen] = useState(false);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [localContextOpen, setLocalContextOpen] = useState(true);

  const dispatch = useAppDispatch();

  const contextedItem = useAppSelector(getContextedItem);
  const selectedFolder = useAppSelector(getSelectedFolder);

  /**
   * Handles the mouse move event.
   *
   * @param event The mouse event.
   */
  const mouseMoveHandler = (event: MouseEvent) => {
    setMouseCoordinates({
      x: event.clientX,
      y: event.clientY,
    });
  };

  useEffect(() => {
    window.addEventListener('contextmenu', mouseMoveHandler);
    return () => {
      window.removeEventListener('contextmenu', mouseMoveHandler);
    };
  }, []);

  const contextMenuStyles = `w-full h-5 bg-purple-50 pl-3 border-b border-gray-800 hover:bg-gray-300
    flex overflow-hidden text-sm whitespace-nowrap cursor-pointer`;

  /**
   * Closes the modal.
   */
  function closeModal() {
    setDeleteConfirmOpen(false);
    setMoveModalOpen(false);
    setLocalContextOpen(true);
    setRenameModalOpen(false);
    dispatch(setContextMenuOpen(false));
  }

  const menuProps = {
    open: true,
    closeModal,
    contextedItem,
    selectedFolder,
  };

  const contextMenuOpen = useAppSelector(getContextMenuOpen);

  return (
    <>
      {contextMenuOpen && localContextOpen && (
        <ClickAwayListener
          mouseEvent="onMouseDown"
          onClickAway={() => {
            deleteConfirmOpen ? null : dispatch(setContextMenuOpen(false));
          }}
        >
          <Box
            className="w-48 bg-red-100 fixed rounded-md overflow-hidden shadow-lg box-border border-x border-t border-gray-800"
            top={mouseCoordinates.y}
            left={mouseCoordinates.x}
          >
            <Box className="w-full h-5 bg-gray-400 pl-3 border-b border-gray-800 whitespace-nowrap flex overflow-hidden text-sm font-bold">
              {contextedItem}
            </Box>
            <Box
              className={contextMenuStyles}
              onClick={() => {
                setDeleteConfirmOpen(true);
                setLocalContextOpen(false);
              }}
            >
              Delete
            </Box>
            {selectedFolder ? (
              <Box
                className={contextMenuStyles}
                onClick={() => {
                  setMoveModalOpen(true);
                  setLocalContextOpen(false);
                }}
              >
                Move
              </Box>
            ) : (
              <Box
                className={contextMenuStyles}
                onClick={() => {
                  setLocalContextOpen(false);
                  setRenameModalOpen(true);
                }}
              >
                Rename
              </Box>
            )}
          </Box>
        </ClickAwayListener>
      )}
      <DeleteModal {...{ ...menuProps, open: deleteConfirmOpen }} />
      <MoveModal {...{ ...menuProps, open: moveModalOpen }} />
      <RenameModal {...{ ...menuProps, open: renameModalOpen }} />
    </>
  );
}

export interface IContextMenusProps {
  open: boolean;
  closeModal: () => void;
  contextedItem: string;
  selectedFolder: string;
}
