import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { CSDesktopState } from '../../slices';
import folderCases from './folderCases';
import emailCases from './emailCases';
import accountCases from './accountCases';
import masterCases from './masterCases';

/**
 * Case builder for the reducers
 *
 * @param builder The builder to add the cases to
 */
export function caseBuilder(
  builder: ActionReducerMapBuilder<CSDesktopState>,
): ActionReducerMapBuilder<CSDesktopState> {
  masterCases(builder);
  folderCases(builder);
  emailCases(builder);
  accountCases(builder);
  return builder;
}
