import { Box, Button, ClickAwayListener, Slider, Stack } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../data/TypedRedux';
import { getUserMenuOpen, getUsername, setUserMenuOpen } from '../../../state/slices';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { logoutUserThunk } from '../../../state/thunks/logoutUserThunk';

/**
 * The user menu.
 */
export function UserMenu() {
  const userMenuOpen = useAppSelector(getUserMenuOpen);
  const username = useAppSelector(getUsername);

  const dispatch = useAppDispatch();

  return (
    <>
      {userMenuOpen ? (
        <ClickAwayListener onClickAway={() => dispatch(setUserMenuOpen(false))}>
          <Box>
            <Box className="w-10 h-10 absolute top-8 right-4 z-40 bg-purple-50 rotate-45 shadow-md" />
            <Box className="w-40 h-32 absolute top-7 right-2 z-50 rounded-md shadow-md bg-purple-50 px-4 flex justify-start items-center flex-col">
              <Box className="w-full h-1/2 flex justify-start items-center gap-2 overflow-hidden">
                <AccountCircleIcon sx={{ fontSize: '1rem' }} />
                <Box className="mb-1 text-xs">{username}</Box>
              </Box>
              <Box className="bg-gray-400 w-full -mt-3" height="1px" />
              <Stack spacing={2} direction="row" alignItems="center" width="100%">
                <VolumeDown sx={{ fontSize: '1rem' }} />
                <Slider aria-label="Volume" max={100} size="small" />
                <VolumeUp sx={{ fontSize: '1rem' }} />
              </Stack>
              <Button
                variant="contained"
                color="primary"
                onClick={() => dispatch(logoutUserThunk())}
                size="small"
                sx={{ fontSize: '0.6rem', marginTop: '0.5rem' }}
              >
                Log Out
              </Button>
            </Box>
          </Box>
        </ClickAwayListener>
      ) : (
        <></>
      )}
    </>
  );
}
