import { Box, TextField, Button } from '@mui/material';
import { useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../data/TypedRedux';
import { getEmail, setEmail } from '../../../../state/slices';
import { updateEmailThunk } from '../../../../state/thunks/update/updateEmailThunk';
import { appConstants } from '../../../../data/constants';
import { addCSAlert } from '../../../../data/addCSAlert';

/**
 * The update email menu.
 *
 * @param props The properties of the update email menu.
 * @param props.closeSettings The function to close the settings menu.
 */
export function UpdateEmailMenu(props: IUpdateEmailMenuProps) {
  const newEmailRef = useRef<HTMLInputElement>(null);

  const email = useAppSelector(getEmail);
  const dispatch = useAppDispatch();

  /**
   * Handles the update of the email.
   */
  const handleUpdateEmail = () => {
    if (newEmailRef.current) {
      const newEmail = newEmailRef.current.value;
      if (newEmail.length !== 0) {
        if (newEmail.includes('@') && newEmail.includes('.')) {
          dispatch(
            updateEmailThunk({
              token: localStorage.getItem('Token') || '',
              email: newEmail,
            }),
          )
            .unwrap()
            .then((result) => {
              if (result.status === 'Succeeded') {
                addCSAlert('Successfully updated email.', 'success');
                dispatch(setEmail(newEmail));
                props.closeSettings();
              }
            });
        } else {
          addCSAlert('Invalid email format. Please make sure your email is valid', 'warning');
        }
      } else {
        addCSAlert('Please fill in all fields', 'warning');
      }
    }
  };

  return (
    <Box className="h-full w-full flex flex-col justify-start items-center gap-4 overflow-x-auto">
      <Box className="text-center text-2xl mt-2" fontFamily={appConstants.toolbarFont}>
        Update Email
      </Box>
      <Box className="text-center text-lg mt-2 font-bold">{email}</Box>
      <TextField
        label="New Email"
        variant="filled"
        type="email"
        defaultValue={email}
        inputRef={newEmailRef}
        size="small"
        className="rounded-full"
        key={email}
      />
      <Button variant="contained" onClick={handleUpdateEmail}>
        Update
      </Button>
    </Box>
  );
}

interface IUpdateEmailMenuProps {
  closeSettings: () => void;
}
