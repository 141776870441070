import { Box, Typography } from '@mui/material';
import { appConstants } from '../../../../data/constants';
import { setAccountInfoOpen } from '../../../../state/slices';
import CloseIcon from '../../../../assets/CloseIcon.png';
import { useAppDispatch } from '../../../../data/TypedRedux';

/**
 * The Info Header for the Account Info Menu.
 *
 * @param props The properties of the Info Header.
 * @param props.handleChangeEditMode The function to handle changing the edit mode.
 * @param props.editMode Whether the edit mode is on.
 * @param props.setEditMode The function to set the edit mode.
 */
export function InfoHeader(props: IInfoHeaderProps) {
  const dispatch = useAppDispatch();

  return (
    <Box className="w-full p-1 flex justify-between items-center bg-gray-400 z-20 absolute top-0 px-2">
      <Box className="flex flex-row gap-1">
        <Typography
          className="hover:bg-gray-500 cursor-pointer px-1 text-center rounded-md"
          onClick={() => props.handleChangeEditMode()}
          fontSize="0.8rem"
        >
          {props.editMode ? 'Save' : 'Edit'}
        </Typography>
        {props.editMode && (
          <Typography
            className="hover:bg-gray-500 cursor-pointer px-1 text-center rounded-md"
            onClick={() => props.setEditMode(false)}
            fontSize="0.8rem"
          >
            Cancel
          </Typography>
        )}
      </Box>
      <Typography
        className="absolute top-1 left-1/2 -translate-x-1/2"
        fontFamily={appConstants.toolbarFont}
      >
        Notepad
      </Typography>
      <img
        src={CloseIcon}
        alt="Close Icon"
        className="h-6 w-6 cursor-pointer transition-all hover:scale-125"
        onClick={() => dispatch(setAccountInfoOpen(false))}
      />
    </Box>
  );
}

export interface IInfoHeaderProps {
  handleChangeEditMode: () => void;
  editMode: boolean;
  setEditMode: (editMode: boolean) => void;
}
