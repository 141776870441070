import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiConstants } from '../../../data/constants';

export interface IUpdateFolders {
  token: string;
  old_name: string;
  new_name: string;
}

export const updateFolderThunk = createAsyncThunk(
  'CSDesktop/updateFolderThunk',
  async (props: IUpdateFolders) => {
    const response = await fetch(apiConstants.apiUrl + '/updatefolder', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: props.token,
      },
      body: JSON.stringify({ old_name: props.old_name, new_name: props.new_name }),
    });
    const data = await response.json();
    return data;
  },
);
