import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiConstants } from '../../../data/constants';

export const getUserFolders = createAsyncThunk(
  'CSDesktop/getUserFolders',
  async (token: string) => {
    const response = await fetch(apiConstants.apiUrl + '/pull', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token,
      },
    });
    const data = await response.json();
    return data;
  },
);
