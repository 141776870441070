import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiConstants } from '../../../data/constants';

export interface IUpdateAccount {
  token: string;
  old_account: {
    username: string;
    password: string;
    accountid: string;
    folder: string;
  };
  new_account: {
    username: string;
    password: string;
    accountid: string;
    folder: string;
  };
}

export const updateAccountThunk = createAsyncThunk(
  'CSDesktop/updateAccountThunk',
  async (props: IUpdateAccount) => {
    const response = await fetch(apiConstants.apiUrl + '/updateaccount', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: props.token,
      },
      body: JSON.stringify({ ...props, token: '' }),
    });
    const data = await response.json();
    return data;
  },
);
