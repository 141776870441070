import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { desktopLogout, menusLogout } from '../slices';

/**
 * Logs the user out of the application completely
 */
export function logoutUserThunk(): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch) => {
    dispatch(menusLogout());
    dispatch(desktopLogout());
  };
}
