import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { CSDesktopState } from '../../slices';
import { updateMasterAccountThunk } from '../../thunks/update/updateMasterAccountThunk';
import { userActiveThunk } from '../../thunks/userActiveThunk';
import { validateUser } from '../../thunks/validateUser';
import { errorParser } from '../errorParser';

/**
 * Master cases for the builder
 *
 * @param builder The builder to add the cases to
 */
export default function emailCases(
  builder: ActionReducerMapBuilder<CSDesktopState>,
): ActionReducerMapBuilder<CSDesktopState> {
  builder
    // * Validate User * //
    .addCase(validateUser.pending, (state) => {
      state.loading = true;
    })
    .addCase(validateUser.fulfilled, (state, action) => {
      const { status, token } = action.payload;
      if (status === 'Succeeded') {
        state.isUserLoggedIn = true;
        state.token = token;
      } else {
        errorParser(token, state);
      }
    })
    .addCase(validateUser.rejected, (state) => {
      errorParser('', state);
      state.isUserLoggedIn = false;
      state.loading = false;
    })
    // * User Active * //
    .addCase(userActiveThunk.fulfilled, (state, action) => {
      const { status, token } = action.payload;
      if (status === 'Succeeded') {
        state.token = token;
      } else {
        errorParser(token, state);
      }
    })
    .addCase(userActiveThunk.rejected, (state) => {
      errorParser('', state);
    })
    // * Update Master Account * //
    .addCase(updateMasterAccountThunk.fulfilled, (state, action) => {
      const { status, token } = action.payload;
      if (status !== 'Succeeded') {
        errorParser(token, state);
      }
    })
    .addCase(updateMasterAccountThunk.rejected, (state) => {
      errorParser('', state);
    });

  return builder;
}
