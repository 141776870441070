import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { appConstants } from '../../data/constants';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../data/TypedRedux';
import { getUserMenuOpen, setUserMenuOpen } from '../../state/slices';

// Size of the icons
const iconSize = '1.1rem';

const style = {
  width: iconSize,
  height: iconSize,
  color: appConstants.toolbarColor,
};

/**
 * The power and sound icons of the tool bar.
 *
 * @returns The JSX Element of the power and sound icons.
 */
export function PowerAndSound(): JSX.Element {
  const userMenuOpen = useAppSelector(getUserMenuOpen);
  const dispatch = useAppDispatch();

  return (
    <Box
      height="wrap-content"
      display="flex"
      alignItems="center"
      className="cursor-pointer"
      onClick={() => dispatch(setUserMenuOpen(!userMenuOpen))}
    >
      <VolumeUpIcon
        sx={{
          ...style,
          marginLeft: '0.75rem',
        }}
      />
      <PowerSettingsNewIcon
        sx={{
          ...style,
          marginLeft: '0.25rem',
        }}
      />
      <ArrowRight
        sx={{
          ...style,
          marginLeft: '0.1rem',
          transition: 'all 0.1s ease-out',
          transform: userMenuOpen ? 'rotate(90deg)' : 'rotate(0deg)',
        }}
      />
    </Box>
  );
}
