import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { appConstants } from '../../data/constants';

// Size of the time information
const timeSize = appConstants.toolbarContentSize;

const style = {
  fontSize: timeSize,
  color: appConstants.toolbarColor,
  fontFamily: appConstants.toolbarFont,
};

/**
 * The time information of the tool bar.
 *
 * @returns The JSX Element of the time information.
 * @see https://day.js.org/docs/en/display/format
 */
export function TimeInfo(): JSX.Element {
  // The current time
  const [time, setTime] = useState(dayjs());

  // Update the time every second
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(dayjs());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      zIndex={100}
      position="absolute"
      left="50%"
      sx={{ transform: 'translateX(-50%)' }}
    >
      <Typography
        sx={{
          ...style,
          marginRight: '0.2rem',
        }}
      >
        {time.format('ddd MMM DD')},{' '}
      </Typography>
      <Typography sx={style}>{time.format('h:mm:ss')}</Typography>
    </Box>
  );
}
