import { addCSAlert } from '../../data/addCSAlert';
import { CSDesktopState, desktopInitialState } from '../slices';

/**
 * Resets the state to the initial state. Intentionally does not reset menu state
 * so the user can log be logged out and log back in without having to re-open
 * any menus they had open.
 *
 * @param state The state to reset
 */
function resetState(state: CSDesktopState) {
  Object.assign(state, desktopInitialState);
}

/**
 * Parses the error token and displays an alert
 *
 * @param token The token to parse
 * @param state The state to reset
 */
export function errorParser(token: string, state: CSDesktopState) {
  switch (token) {
    case '':
      addCSAlert('An error has occured, please try again.', 'error');
      break;
    case 'Expired':
      addCSAlert('Your session has expired, please log in again.', 'error');
      resetState(state);
      break;
    case 'DecodeErr':
      addCSAlert('A decoding error has occured, please try again.', 'error');
      resetState(state);
      break;
    case 'TokenErr':
      addCSAlert('A token error has occured, please try again.', 'error');
      resetState(state);
      break;
    case 'NoCombo':
      addCSAlert('No username/password combination found.', 'error');
      resetState(state);
      break;
    case 'UsernameErr':
      addCSAlert('Username must be between 5 and 25 characters.', 'warning');
      resetState(state);
      break;
    case 'PasswordErr':
      addCSAlert('Password must be between 5 and 25 characters.', 'warning');
      resetState(state);
      break;
    case 'FolderExists':
      addCSAlert('Folder already exists.', 'error');
      break;
    case 'AccountExists':
      addCSAlert('Account already exists.', 'error');
      break;
    case 'EmailErr':
      addCSAlert('Email must be a valid email address and less than 100 characters.', 'warning');
      break;
    default:
      addCSAlert(`DEVELOPER ERROR: ${token}`, 'error');
      break;
  }
}
