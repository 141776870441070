/* eslint-disable jsdoc/require-jsdoc */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { caseBuilder } from '../helpers/caseBuilder/caseBuilder';
import { desktopReducerBuilder } from '../helpers/reducerBuilders/desktopReducerBuilder';

export type FolderTree = Record<string, Record<string, { username: string; password: string }>>;

export interface CSDesktopState {
  isUserLoggedIn: boolean;
  loading: boolean;
  currentPage: number;
  username: string;
  token: string;
  folders: FolderTree;
  selectedFolder: string;
  contextedItem: string;
  ascendingSort: boolean;
  email: string;
}

export const desktopInitialState: CSDesktopState = {
  isUserLoggedIn: false,
  loading: false,
  currentPage: 0,
  username: '',
  token: '',
  folders: {},
  selectedFolder: '',
  contextedItem: '',
  ascendingSort: true,
  email: '',
};

export const CSDesktopSlice = createSlice({
  name: 'CSDesktop',
  initialState: desktopInitialState,
  reducers: desktopReducerBuilder(),
  extraReducers: (builder) => {
    caseBuilder(builder);
  },
});

export const {
  desktopLogout,
  setIsUserLoggedIn,
  setCurrentPage,
  setUsername,
  setFolders,
  setSelectedFolder,
  setAscendingSort,
  setEmail,
  setContextedItem,
} = CSDesktopSlice.actions;
export const getDesktopState = (state: RootState) => state.CSDesktop;
export const getCurrentPage = (state: RootState) => state.CSDesktop.currentPage;
export const getIsUserLoggedIn = (state: RootState) => state.CSDesktop.isUserLoggedIn;
export const getUsername = (state: RootState) => state.CSDesktop.username;
export const getFolders = (state: RootState) => state.CSDesktop.folders;
export const getIsLoading = (state: RootState) => state.CSDesktop.loading;
export const getSelectedFolder = (state: RootState) => state.CSDesktop.selectedFolder;
export const getContextedItem = (state: RootState) => state.CSDesktop.contextedItem;
export const getAscendingSort = (state: RootState) => state.CSDesktop.ascendingSort;
export const getEmail = (state: RootState) => state.CSDesktop.email;
export const CSDesktopReducer = CSDesktopSlice.reducer;
