import { Box, Typography } from '@mui/material';
import { appConstants } from '../../../../../data/constants';
import { changelog } from '../../../../../data/changelog';
import { VersionChanges } from './VersionChanges';

/**
 * The changelog page.
 */
export function ChangelogPage() {
  return (
    <Box className="h-full w-full flex flex-col justify-start items-center gap-4 overflow-x-auto py-1 px-4 overflow-y-scroll">
      <Typography fontSize="1.5rem" fontFamily={appConstants.toolbarFont}>
        Changelog
      </Typography>
      {changelog.map((version, index) => (
        <VersionChanges key={index} {...version} />
      ))}
    </Box>
  );
}
