import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../data/TypedRedux';
import { getFolders, setFolders } from '../../../../state/slices';
import { updateFolderThunk } from '../../../../state/thunks/update/updateFolderThunk';
import { useState } from 'react';
import { IContextMenusProps } from '../ContextMenu';
import { appConstants } from '../../../../data/constants';
import { addCSAlert } from '../../../../data/addCSAlert';

/**
 * The rename modal.
 *
 * @param props The properties of the rename modal.
 * @param props.open Whether the modal is open.
 * @param props.closeModal The function to close the modal.
 * @param props.contextedItem The item that is contexted.
 * @param props.selectedFolder The selected folder.
 */
export function RenameModal(props: IContextMenusProps) {
  const dispatch = useAppDispatch();

  const [localFolderName, setLocalFolderName] = useState(props.contextedItem);

  const token = localStorage.getItem('Token');

  const allFolders = useAppSelector(getFolders);

  /**
   * Updates the folder name.
   */
  const updateFolderName = () => {
    if (localFolderName.trim() === '') {
      addCSAlert('Error, folder name cannot be empty.', 'error');
      return;
    }
    if (localFolderName.trim() === props.contextedItem) {
      props.closeModal();
      return;
    }
    if (token === null) {
      addCSAlert('Error, no token detected.', 'error');
      return;
    }
    dispatch(
      updateFolderThunk({
        token: token,
        old_name: props.contextedItem,
        new_name: localFolderName,
      }),
    )
      .unwrap()
      .then((result) => {
        if (result.status === 'Succeeded') {
          const newFolders = { ...allFolders };
          newFolders[localFolderName] = newFolders[props.contextedItem];
          delete newFolders[props.contextedItem];
          dispatch(setFolders(newFolders));
          addCSAlert('Folder renamed successfully.', 'success');
          props.closeModal();
        }
      });
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.closeModal();
      }}
      disableEnforceFocus
      disableAutoFocus
    >
      <Box className="w-64 h-80 bg-purple-50 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md border border-black flex flex-col justify-evenly items-center">
        <Typography fontSize="1.5rem" textAlign="center" fontFamily={appConstants.toolbarFont}>
          Rename {props.contextedItem}
        </Typography>
        <TextField
          id="folder-name"
          label="Folder Name"
          variant="filled"
          type="text"
          value={localFolderName}
          onChange={(e) => setLocalFolderName(e.target.value)}
          size="small"
          className="rounded-full"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            updateFolderName();
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            props.closeModal();
          }}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
}
