import { Box, Typography } from '@mui/material';
import cheddarSafeLogo from '../../assets/cheddarSafeRevisedLogo.png';
import { appConstants } from '../../data/constants';

// Size of the logo box
const contentSize = appConstants.toolbarContentSize;

// Styles for the logo box
const styles = {
  logo: {
    width: contentSize,
    height: contentSize,
    marginRight: '0.25rem',
  },
  text: {
    fontSize: contentSize,
  },
};

/**
 * The logo box of the tool bar.
 *
 * @returns The JSX Element of the logo box.
 */
export function CSLogoBox(): JSX.Element {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      marginLeft="0.25rem"
    >
      <img src={cheddarSafeLogo} alt="CheddarSafe Logo" style={styles.logo} />
      <Typography
        sx={styles.text}
        color={appConstants.toolbarColor}
        fontFamily={appConstants.toolbarFont}
      >
        Cheddar Safe
      </Typography>
    </Box>
  );
}
