import { Box, Typography } from '@mui/material';
import { CSToolBar } from '../toolbar/CSToolbar';
import { appConstants } from '../../data/constants';
import cheddarSafeRevisedLogo from '../../assets/cheddarSafeRevisedLogo.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UndoIcon from '../../assets/UndoIcon.png';
import { useAppDispatch, useAppSelector } from '../../data/TypedRedux';
import { getIsLoading, getUsername, setUsername } from '../../state/slices';
import { validateUser } from '../../state/thunks/validateUser';
import { getUserFolders } from '../../state/thunks/get/getUserFolders';
import { LoadingScreen } from '../desktop/LoadingScreen';

const styles = {
  background: {
    backgroundImage: 'linear-gradient(to right, rgba(86, 38, 131,1), rgba(97, 61, 193,0.65))',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  loginLogo: {
    height: '100px',
    width: 'wrap-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
  },

  loginIcon: {
    height: 'fit-content',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '25%',
    left: '50%',
    transform: 'translateX(-50%)',
  },

  loginInput: {
    height: '2rem',
    width: '13rem',
    borderRadius: '0.25rem',
    border: 'none',
    fontSize: '1rem',
    textAlign: 'left',
    marginTop: '1rem',
    paddingLeft: '0.5rem',
  },

  loader: {
    '@keyframes spin': {
      from: {
        transform: 'rotate(0deg) translateY(50%)',
        boxShadow: '0 0 0px 0 blue',
        height: '0',
      },
      to: {
        transform: 'rotate(540deg) translateY(50%)',
        boxShadow: '0 0 100px 0 blue',
        height: '50px',
      },
    },
    '@keyframes expand': {
      from: {
        width: '0',
        height: '0',
        boxShadow: '0 0 0px 0 blue',
      },
      to: {
        width: '100px',
        height: '100px',
        boxShadow: '0 0 100px 0 blue',
      },
    },
    height: '100px',
    width: '10px',
    bgcolor: 'green',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 50%)',
    top: '50%',
    animation: 'spin 2s linear infinite',
    '&::after': {
      content: '""',
      width: '100px',
      height: '100px',
      bgcolor: 'transparent',
      display: 'block',
      animation: 'expand 2s linear infinite',
      transform: 'translate(calc(-50% + 5px), -50%)',
      position: 'absolute',
      borderRadius: '50%',
      border: '3px solid green',
    },
  },
};

/**
 * The login screen for the desktop application.
 */
export function LoginDesktop(): JSX.Element {
  const dispatch = useAppDispatch();

  const username = useAppSelector(getUsername);
  const isLoading = useAppSelector(getIsLoading);

  /**
   * Handles the enter key press event.
   *
   * @param e The key press event.
   */
  function onEnterPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter' && e.currentTarget.value !== '') {
      if (username === '') {
        dispatch(setUsername(e.currentTarget.value));
      } else {
        dispatch(
          validateUser({
            mname: username,
            mpass: e.currentTarget.value,
          }),
        )
          .unwrap()
          .then((result) => {
            if (result.status === 'Succeeded') {
              dispatch(getUserFolders(result.token));
            }
          });
      }
      e.currentTarget.value = '';
    }
  }

  return (
    <>
      <CSToolBar toolBarHeight={appConstants.toolbarHeight} />
      <Box sx={styles.background} />
      {!isLoading ? (
        <Box sx={styles.loginIcon}>
          <AccountCircleIcon sx={{ fontSize: '5rem', color: 'rgba(0,0,0,0.8)' }} />
          <Typography fontSize="1.5rem" fontFamily={appConstants.toolbarFont} marginTop="0.5rem">
            {username === '' ? <br /> : username}
          </Typography>
          <input
            type={username === '' ? 'text' : 'password'}
            placeholder={username === '' ? 'Username' : 'Password'}
            style={styles.loginInput as React.CSSProperties}
            onKeyDown={onEnterPress}
          />
          {username && (
            <img
              src={UndoIcon}
              alt="Undo Icon"
              className="absolute w-8 h-8 bottom-0 -right-10 hover:scale-125 transition-all"
              onClick={() => dispatch(setUsername(''))}
            />
          )}
        </Box>
      ) : (
        <LoadingScreen />
      )}
      <Box sx={styles.loginLogo}>
        <img src={cheddarSafeRevisedLogo} alt="logo" style={{ height: '5rem' }} />
        <Typography fontSize="2rem" fontFamily={appConstants.toolbarFont} marginLeft="0.25rem">
          Cheddar Safe
        </Typography>
      </Box>
    </>
  );
}
