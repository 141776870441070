import { Box, Modal } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../data/TypedRedux';
import { getEmail, getSettingsOpen, setEmail, setSettingsOpen } from '../../../state/slices';
import MasterAccountIcon from '../../../assets/MasterAccountIcon.png';
import ThunderbirdIcon from '../../../assets/ThunderbirdIcon.png';
import AboutIcon from '../../../assets/AboutIcon.png';
import ChangelogIcon from '../../../assets/Changelogicon.png';
import { useEffect, useState } from 'react';
import { getEmailThunk } from '../../../state/thunks/get/getEmailThunk';
import { userActiveThunk } from '../../../state/thunks/userActiveThunk';
import { EditAccountMenu } from './settingsMenus/editAccount/EditAccountMenu';
import { UpdateEmailMenu } from './settingsMenus/UpdateEmailMenu';
import { AboutPage } from './settingsMenus/AboutPage';
import { ChangelogPage } from './settingsMenus/changelog/ChangelogPage';
import { appConstants } from '../../../data/constants';

const menuOptions = [
  {
    name: 'Edit Account',
    icon: MasterAccountIcon,
    alt: 'Edit Account icon',
  },
  {
    name: 'Update Email',
    icon: ThunderbirdIcon,
    alt: 'Update Email icon',
  },
  {
    name: 'Changelog',
    icon: ChangelogIcon,
    alt: 'Changelog icon',
  },
  {
    name: 'About',
    icon: AboutIcon,
    alt: 'About icon',
  },
];

/**
 * The settings menu.
 */
export function SettingsMenu() {
  const settingsOpen = useAppSelector(getSettingsOpen);
  const dispatch = useAppDispatch();

  const [selectedPage, setSelectedPage] = useState('');

  const email = useAppSelector(getEmail);

  /**
   * Closes the settings menu.
   */
  const closeSettings = () => {
    dispatch(setSettingsOpen(false));
    setSelectedPage('');
  };

  /**
   * Gets the menu options.
   */
  const getMenuOptions = () => {
    return (
      <>
        {menuOptions.map((option) => (
          <Box
            className="hover:bg-gray-300 cursor-pointer text-center flex items-center pl-2 border-b border-gray-400"
            key={option.name}
            fontFamily={appConstants.toolbarFont}
            onClick={() => {
              setSelectedPage(option.name);
            }}
          >
            <img src={option.icon} alt={option.alt} className="h-5 mr-2" />
            {option.name}
          </Box>
        ))}
      </>
    );
  };

  /**
   * Gets the settings page.
   */
  const getSettingsPage = () => {
    switch (selectedPage) {
      case 'Edit Account':
        return <EditAccountMenu closeSettings={closeSettings} />;
      case 'Update Email':
        return <UpdateEmailMenu closeSettings={closeSettings} />;
      case 'About':
        return <AboutPage />;
      case 'Changelog':
        return <ChangelogPage />;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (settingsOpen && email) {
      dispatch(userActiveThunk({ token: localStorage.getItem('Token') || '' }));
    } else if (settingsOpen && !email) {
      dispatch(
        getEmailThunk({
          token: localStorage.getItem('Token') || '',
        }),
      )
        .unwrap()
        .then((result) => {
          if (result.status === 'Succeeded') {
            dispatch(setEmail(result.email));
          }
        });
    }
  }, [settingsOpen]);

  return (
    <Modal
      open={settingsOpen}
      onClose={() => {
        closeSettings();
      }}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      <Box
        width="750px"
        maxWidth={window.innerWidth - 200 + 'px'}
        height="600px"
        maxHeight={window.innerHeight - 100 + 'px'}
        top="10%"
        className="border border-black absolute bg-purple-50 rounded-md left-1/2 -translate-x-1/2 flex justify-center items-center overflow-hidden"
      >
        <Box
          className="w-full h-10 bg-gray-400 absolute top-0 flex justify-center items-center text-xl shadow-md z-20"
          fontFamily={appConstants.toolbarFont}
        >
          Settings
        </Box>
        <Box width="150px" className="bg-purple-50 h-full shadow-md z-10 pt-10 border-r">
          {getMenuOptions()}
        </Box>
        <Box width="600px" className="bg-purple-50 h-full pt-10">
          {getSettingsPage()}
        </Box>
      </Box>
    </Modal>
  );
}
