import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { CSDesktopReducer } from './slices';
import { MenusReducer } from './slices/MenusSlice';

const rootReducer = combineReducers({
  CSDesktop: CSDesktopReducer,
  Menus: MenusReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
