import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiConstants } from '../../../data/constants';

export interface IUpdateEmail {
  token: string;
  email: string;
}

export const updateEmailThunk = createAsyncThunk(
  'CSDesktop/updateEmailThunk',
  async (user: IUpdateEmail) => {
    const response = await fetch(apiConstants.apiUrl + '/updateemail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: user.token,
      },
      body: JSON.stringify({ mname: 'username', mpass: 'password', email: user.email }),
    });
    const data = await response.json();
    return data;
  },
);
