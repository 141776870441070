/* eslint-disable jsdoc/require-jsdoc */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { menusReducerBuilder } from '../helpers/reducerBuilders/menusReducerBuilder';

export interface MenusState {
  creationModalOpen: boolean;
  contextMenuOpen: boolean;
  accountInfoOpen: boolean;
  settingsOpen: boolean;
  searchOpen: boolean;
  userMenuOpen: boolean;
}

export const menusInitialState: MenusState = {
  creationModalOpen: false,
  contextMenuOpen: false,
  accountInfoOpen: false,
  settingsOpen: false,
  searchOpen: false,
  userMenuOpen: false,
};

export const MenusSlice = createSlice({
  name: 'Menus',
  initialState: menusInitialState,
  reducers: menusReducerBuilder(),
});

export const {
  menusLogout,
  setModalOpen,
  setContextMenuOpen,
  setAccountInfoOpen,
  setSettingsOpen,
  setSearchOpen,
  setUserMenuOpen,
} = MenusSlice.actions;
export const getModalOpen = (state: RootState) => state.Menus.creationModalOpen;
export const getContextMenuOpen = (state: RootState) => state.Menus.contextMenuOpen;
export const getAccountInfoOpen = (state: RootState) => state.Menus.accountInfoOpen;
export const getSettingsOpen = (state: RootState) => state.Menus.settingsOpen;
export const getSearchOpen = (state: RootState) => state.Menus.searchOpen;
export const getUserMenuOpen = (state: RootState) => state.Menus.userMenuOpen;
export const MenusReducer = MenusSlice.reducer;
