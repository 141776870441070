import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './state/store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

store.subscribe(() => {
  localStorage.setItem('Token', store.getState().CSDesktop.token);
});

store.subscribe(() => {
  localStorage.setItem('Folders', JSON.stringify(store.getState().CSDesktop.folders));
});

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
);
