import { Box, Toolbar } from '@mui/material';
import { CSLogoBox } from './CSLogoBox';
import { TimeInfo } from './TimeInfo';
import { PowerAndSound } from './PowerAndSound';

/**
 * The tool bar of the Cheddar Safe application.
 *
 * @param props The properties of the tool bar.
 * @returns The JSX Element of the tool bar.
 */
export function CSToolBar(props: IToolBarProps): JSX.Element {
  // Styles for the tool bar
  const styles = {
    CSToolBar: {
      width: '100%',
      height: props.toolBarHeight + 'px',
      backgroundColor: '#131313',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 100,
      padding: '0 0.5rem',
      margin: 0,
      minHeight: '20px',
      justifyContent: 'space-between',
    },
  };

  return (
    <Box>
      <Toolbar
        sx={styles.CSToolBar}
        color="#a7a7a7"
        variant="dense"
        className="shadow-md"
        disableGutters
      >
        <CSLogoBox />
        <PowerAndSound />
      </Toolbar>
      <TimeInfo />
    </Box>
  );
}

export interface IToolBarProps {
  toolBarHeight: number;
}
