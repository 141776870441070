import { Modal, Box, Typography, Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../data/TypedRedux';
import { setFolders, setContextMenuOpen, getFolders } from '../../../../state/slices';
import { deleteAccountThunk } from '../../../../state/thunks/delete/deleteAccountThunk';
import { deleteFolderThunk } from '../../../../state/thunks/delete/deleteFolderThunk';
import { IContextMenusProps } from '../ContextMenu';
import { appConstants } from '../../../../data/constants';
import { addCSAlert } from '../../../../data/addCSAlert';

/**
 * The delete modal.
 *
 * @param props The properties of the delete modal.
 * @param props.open Whether the modal is open.
 * @param props.closeModal The function to close the modal.
 * @param props.contextedItem The item that is contexted.
 * @param props.selectedFolder The selected folder.
 */
export function DeleteModal(props: IContextMenusProps) {
  const dispatch = useAppDispatch();
  const token = localStorage.getItem('Token');
  const allFolders = useAppSelector(getFolders);

  /**
   * Deletes the item.
   */
  function deleteItem() {
    if (token === null) {
      addCSAlert('Error, no token detected.', 'error');
      return;
    }
    if (props.contextedItem && props.selectedFolder) {
      dispatch(
        deleteAccountThunk({
          accountid: props.contextedItem,
          token: token,
          username: '',
          password: '',
          folder: props.selectedFolder,
        }),
      )
        .unwrap()
        .then((result) => {
          if (result.status === 'Succeeded') {
            const newAccounts = { ...allFolders[props.selectedFolder] };
            delete newAccounts[props.contextedItem];
            dispatch(setFolders({ ...allFolders, [props.selectedFolder]: newAccounts }));
            dispatch(setContextMenuOpen(false));
            addCSAlert('Account deleted successfully.', 'success');
          }
        });
    } else {
      dispatch(deleteFolderThunk({ folderName: props.contextedItem, token: token }))
        .unwrap()
        .then((result) => {
          if (result.status === 'Succeeded') {
            const newFolders = { ...allFolders };
            delete newFolders[props.contextedItem];
            dispatch(setFolders(newFolders));
            dispatch(setContextMenuOpen(false));
            addCSAlert('Folder deleted successfully.', 'success');
          }
        });
    }
  }

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.closeModal();
      }}
      disableEnforceFocus
      disableAutoFocus
    >
      <Box
        className="w-max h-48 bg-purple-50 fixed top-1/2 left-1/2 
        -translate-x-1/2 -translate-y-full rounded-md border border-black 
        flex flex-col justify-evenly items-center px-5"
      >
        <Typography fontSize="1.5rem" textAlign="center" fontFamily={appConstants.toolbarFont}>
          Delete {props.contextedItem}?
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            deleteItem();
            props.closeModal();
          }}
        >
          Delete
        </Button>
      </Box>
    </Modal>
  );
}
