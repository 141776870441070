import { Box } from '@mui/material';
import FirefoxLogo from '../../../assets/FirefoxLogo.png';
import ThunderbirdLogo from '../../../assets/ThunderbirdLogo.png';
import FolderExplorer from '../../../assets/FolderExplorer.png';
import RhythmboxLogo from '../../../assets/RhythmboxLogo.png';
import LibreOfficeLogo from '../../../assets/LibreOfficeLogo.png';
import SettingsIcon from '../../../assets/SettingsIcon.png';
import BackIcon from '../../../assets/BackIcon.png';
import AddFolderIcon from '../../../assets/AddFolderIcon.png';
import AddAccountIcon from '../../../assets/AddAccountIcon.png';
import AscendingIcon from '../../../assets/AscendingIcon.png';
import DescendingIcon from '../../../assets/DescendingIcon.png';
import SearchIcon from '../../../assets/SearchIcon.png';
import {
  getAscendingSort,
  getSelectedFolder,
  setAscendingSort,
  setModalOpen,
  setSearchOpen,
  setSelectedFolder,
  setSettingsOpen,
} from '../../../state/slices';
import { useAppDispatch, useAppSelector } from '../../../data/TypedRedux';
import { useState } from 'react';

// Styles for the side bar
const styles = {
  logos: {
    width: '100%',
    height: '30px',
    cursor: 'pointer',
    margin: '0.5rem 0',
    transition: 'all 0.1s ease-out',
    zIndex: 11,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
      transform: 'scale(1.2)',
      marginLeft: '1rem',
    },
    '@media (max-width: 1000px)': {
      height: '25px',
      margin: '0.25rem 0',
    },
    '@media (max-width: 700px)': {
      height: '20px',
    },
  },
};

/**
 * The side bar of the desktop.
 *
 * @param props The properties of the side bar.
 * @param props.sideBarWidth The width of the side bar.
 */
export function DesktopSideBar(props: ISideBarProps): JSX.Element {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltopY, setTooltipY] = useState(0);
  const [tooltopText, setTooltipText] = useState('');

  // App dispatch
  const dispatch = useAppDispatch();

  // The selected folder
  const selectedFolder = useAppSelector(getSelectedFolder);

  const ascendingSort = useAppSelector(getAscendingSort);

  /**
   * Opens the tooltip.
   *
   * @param y The y position of the tooltip.
   * @param text The text of the tooltip.
   */
  const openTooltip = (y: number, text: string) => {
    setTooltipOpen(true);
    setTooltipY(y);
    setTooltipText(text);
  };

  const logos = [
    {
      name: 'Firefox',
      logo: FirefoxLogo,
      alt: 'Firefox Logo',
      tooltip: 'UNIMPLEMENTED',
    },
    {
      name: 'Thunderbird',
      logo: ThunderbirdLogo,
      alt: 'Thunderbird Logo',
      tooltip: 'UNIMPLEMENTED',
    },
    {
      name: 'Folder Explorer',
      logo: FolderExplorer,
      alt: 'Folder Explorer Logo',
      tooltip: 'UNIMPLEMENTED',
    },
    {
      name: 'Rhythmbox',
      logo: RhythmboxLogo,
      alt: 'Rhythmbox Logo',
      tooltip: 'UNIMPLEMENTED',
    },
    {
      name: 'Libre Office',
      logo: LibreOfficeLogo,
      alt: 'Libre Office Logo',
      tooltip: 'UNIMPLEMENTED',
    },
    {
      name: 'Search',
      logo: SearchIcon,
      alt: 'Search Logo',
      tooltip: 'Search',
      /**
       * Opens the search modal.
       */
      onClick: () => dispatch(setSearchOpen(true)),
    },
    {
      name: 'Settings',
      logo: SettingsIcon,
      alt: 'Settings Icon',
      tooltip: 'Settings',
      /**
       * Opens the settings modal.
       */
      onClick: () => dispatch(setSettingsOpen(true)),
    },
    {
      name: 'Sort',
      logo: ascendingSort ? AscendingIcon : DescendingIcon,
      alt: 'Sort Icon',
      tooltip: 'Sort',
      /**
       * Sets the sort to the opposite of what it is.
       */
      onClick: () => dispatch(setAscendingSort(!ascendingSort)),
    },
    {
      name: 'Add',
      logo: selectedFolder ? AddAccountIcon : AddFolderIcon,
      alt: 'Add Icon',
      tooltip: `Add ${selectedFolder ? 'Account' : 'Folder'}`,
      /**
       * Opens the modal.
       */
      onClick: () => dispatch(setModalOpen(true)),
    },
  ];

  return (
    <>
      <Box
        bgcolor="rgba(0,0,0,0.85)"
        width={props.sideBarWidth + 'px'}
        className={`overflow-x-clip overflow-y-hidden left-0 top-0
           absolute h-full flex flex-col items-center justify-top pt-8 z-10`}
      >
        {logos.map((logo) => (
          <Box
            sx={styles.logos}
            onMouseEnter={(e) => {
              openTooltip(e.currentTarget.offsetTop, logo.tooltip);
            }}
            onMouseLeave={() => {
              setTooltipOpen(false);
            }}
            key={logo.name}
          >
            <img
              style={{ height: '100%' }}
              src={logo.logo}
              alt={logo.alt}
              loading="lazy"
              onClick={logo.onClick}
            />
          </Box>
        ))}
        {selectedFolder && (
          <Box
            flexGrow="1"
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="center"
            paddingBottom="25px"
          >
            <Box
              sx={styles.logos}
              onMouseEnter={(e) => {
                openTooltip(e.currentTarget.offsetTop, 'Go Back');
              }}
              onMouseLeave={() => {
                setTooltipOpen(false);
              }}
            >
              <img
                style={{ height: '100%' }}
                src={BackIcon}
                alt="Back Icon"
                onClick={() => {
                  dispatch(setSelectedFolder(''));
                  setTooltipOpen(false);
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
      {tooltipOpen && (
        <Box
          position="absolute"
          left="55px"
          top={tooltopY + 'px'}
          bgcolor="rgba(0,0,0,0.85)"
          zIndex="12"
          width="max-content"
          className="absolute text-white text-xs rounded-md z-50 px-2 py-1"
        >
          {tooltopText}
        </Box>
      )}
    </>
  );
}

export interface ISideBarProps {
  sideBarWidth: number;
}
