/* eslint-disable jsdoc/require-jsdoc */
import { PayloadAction } from '@reduxjs/toolkit';
import { MenusState, menusInitialState } from '../../slices/MenusSlice';

/**
 * Menus reducer builder
 */
export function menusReducerBuilder() {
  return {
    menusLogout: (state: MenusState) => {
      Object.assign(state, menusInitialState);
    },
    setModalOpen: (state: MenusState, action: PayloadAction<boolean>) => {
      state.creationModalOpen = action.payload;
    },
    setContextMenuOpen: (state: MenusState, action: PayloadAction<boolean>) => {
      state.contextMenuOpen = action.payload;
    },
    setAccountInfoOpen: (state: MenusState, action: PayloadAction<boolean>) => {
      state.accountInfoOpen = action.payload;
    },
    setSettingsOpen: (state: MenusState, action: PayloadAction<boolean>) => {
      state.settingsOpen = action.payload;
    },
    setSearchOpen: (state: MenusState, action: PayloadAction<boolean>) => {
      state.searchOpen = action.payload;
    },
    setUserMenuOpen: (state: MenusState, action: PayloadAction<boolean>) => {
      state.userMenuOpen = action.payload;
    },
  };
}
