import { Modal, Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../data/TypedRedux';
import { getFolders, setFolders } from '../../../../state/slices';
import { updateAccountThunk } from '../../../../state/thunks/update/updateAccountThunk';
import FolderIcon from '../../../../assets/FolderIcon.png';
import { IContextMenusProps } from '../ContextMenu';
import { addCSAlert } from '../../../../data/addCSAlert';

/**
 * The move modal.
 *
 * @param props The properties of the move modal.
 * @param props.open Whether the modal is open.
 * @param props.closeModal The function to close the modal.
 * @param props.contextedItem The item that is contexted.
 * @param props.selectedFolder The selected folder.
 */
export function MoveModal(props: IContextMenusProps) {
  const dispatch = useAppDispatch();
  const token = localStorage.getItem('Token');
  const allFolders = useAppSelector(getFolders);

  /**
   * Gets the move items.
   */
  const getMoveItems = () => {
    const copyOfFolders = { ...allFolders };
    delete copyOfFolders[props.selectedFolder];

    /**
     * Moves the item and closes the modal.
     *
     * @param folder The folder to move the item to.
     */
    function moveAndClose(folder: string) {
      if (token === null) {
        addCSAlert('Error, no token detected.', 'error');
        return;
      }
      const currentAccount = allFolders[props.selectedFolder][props.contextedItem];
      dispatch(
        updateAccountThunk({
          token: token,
          old_account: {
            username: currentAccount.username,
            password: currentAccount.password,
            accountid: props.contextedItem,
            folder: props.selectedFolder,
          },
          new_account: {
            username: currentAccount.username,
            password: currentAccount.password,
            accountid: props.contextedItem,
            folder: folder,
          },
        }),
      )
        .unwrap()
        .then((result) => {
          if (result.status === 'Succeeded') {
            const newAccountsSelected = { ...allFolders[props.selectedFolder] };
            delete newAccountsSelected[props.contextedItem];
            const newAccounts = { ...allFolders[folder] };
            newAccounts[props.contextedItem] = currentAccount;
            dispatch(
              setFolders({
                ...allFolders,
                [props.selectedFolder]: newAccountsSelected,
                [folder]: newAccounts,
              }),
            );
            addCSAlert('Account moved successfully.', 'success');
            props.closeModal();
          }
        });
    }

    return Object.keys(copyOfFolders)
      .sort()
      .map((folder) => {
        return (
          <Box
            key={folder}
            className="flex gap-2 items-center hover:bg-gray-300 mb-1 cursor-pointer"
            onClick={() => moveAndClose(folder)}
          >
            <Box className="h-6 pl-2">
              <img src={FolderIcon} alt="Folder Icon" className="h-full" />
            </Box>
            <Typography>{folder}</Typography>
          </Box>
        );
      });
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.closeModal();
      }}
      disableEnforceFocus
      disableAutoFocus
    >
      <Box
        className="w-72 h-max bg-purple-50 fixed top-1/2 left-1/2
        -translate-x-1/2 -translate-y-1/2 rounded-md border border-black 
        flex flex-col justify-center items-center py-10"
      >
        <Typography className="top-6 w-4/5 overflow-hidden text-center pb-5">
          Move {props.contextedItem} to...
        </Typography>
        <Box className="w-4/5 h-3/5 border border-black shadow-lg overflow-scroll flex flex-col justify-start">
          {getMoveItems()}
        </Box>
      </Box>
    </Modal>
  );
}
