import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiConstants } from '../../data/constants';

export interface IActive {
  token: string;
}

export const userActiveThunk = createAsyncThunk(
  'CSDesktop/userActiveThunk',
  async (user: IActive) => {
    const response = await fetch(apiConstants.apiUrl + '/active', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: user.token,
      },
    });
    const data = await response.json();
    return data;
  },
);
