import { Box, Button, FormControlLabel, Modal, Switch, TextField, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../data/TypedRedux';
import {
  getFolders,
  getSearchOpen,
  setAccountInfoOpen,
  setContextedItem,
  setSearchOpen,
  setSelectedFolder,
} from '../../../state/slices';
import { useState } from 'react';
import AccountIcon from '../../../assets/AccountIcon.png';
import FolderIcon from '../../../assets/FolderIcon.png';
import { appConstants } from '../../../data/constants';

/**
 * The search modal.
 */
export function SearchModal() {
  const dispatch = useAppDispatch();

  const searchOpen = useAppSelector(getSearchOpen);
  const [searchAccounts, setSearchAccounts] = useState(true);
  const [searchItem, setSearchItem] = useState('');

  const allFolders = useAppSelector(getFolders);

  /**
   * Closes the search modal.
   */
  const closeSearch = () => {
    dispatch(setSearchOpen(false));
    setSearchAccounts(true);
    setSearchItem('');
  };

  /**
   * Gets the search items.
   */
  const getSearchItems = () => {
    const newItems = [];

    if (!searchItem) {
      return null;
    }

    if (searchAccounts) {
      for (const folder in allFolders) {
        for (const account in allFolders[folder]) {
          if (account.toLowerCase().includes(searchItem.toLowerCase())) {
            newItems.push(
              <Box
                key={folder + account}
                className="flex gap-2 items-center mb-1 border-b border-black justify-between w-full p-1"
              >
                <Box className="h-6 w-8 min-w-8 pl-2">
                  <img src={AccountIcon} alt="Account Icon" className="h-full min-w-8" />
                </Box>
                <Typography className="overflow-hidden" width="60%" fontSize="0.8rem">
                  {folder}/{account}
                </Typography>
                <Button
                  key={account}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    closeSearch();
                    dispatch(setAccountInfoOpen(true));
                    dispatch(setContextedItem(account));
                    dispatch(setSelectedFolder(folder));
                  }}
                >
                  <Typography fontSize="0.5rem">Open</Typography>
                </Button>
              </Box>,
            );
          }
        }
      }
    } else {
      for (const folder in allFolders) {
        if (folder.toLowerCase().includes(searchItem.toLowerCase())) {
          newItems.push(
            <Box
              key={folder}
              className="flex gap-2 items-center mb-1 border-b border-black justify-between w-full p-1"
            >
              <Box className="h-6 w-8 min-w-8 pl-2">
                <img src={FolderIcon} alt="Account Icon" className="h-full min-w-8" />
              </Box>
              <Typography className="overflow-hidden" width="60%" fontSize="0.8rem">
                {folder}
              </Typography>
              <Button
                key={folder}
                variant="contained"
                color="primary"
                onClick={() => {
                  closeSearch();
                  dispatch(setContextedItem(''));
                  dispatch(setSelectedFolder(folder));
                }}
              >
                <Typography fontSize="0.5rem">Open</Typography>
              </Button>
            </Box>,
          );
        }
      }
    }
    return newItems;
  };

  return (
    <Modal
      open={searchOpen}
      onClose={() => closeSearch()}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      <Box
        className="w-96 bg-purple-50 border border-black left-1/2 -translate-x-1/2 top-20 absolute rounded-md flex items-center overflow-hidden flex-col gap-2"
        height="450px"
      >
        <Box className="h-10 w-full bg-gray-400 flex justify-center items-center shadow-md">
          <Typography fontFamily={appConstants.toolbarFont}>Search</Typography>
        </Box>
        <FormControlLabel
          control={
            <Switch
              checked={searchAccounts}
              onChange={() => {
                setSearchAccounts(!searchAccounts);
              }}
            />
          }
          label={searchAccounts ? 'Accounts' : 'Folders'}
        />
        <TextField
          id="folder-name"
          label={`Search for ${searchAccounts ? 'accounts' : 'folders'}`}
          variant="filled"
          type="search"
          onChange={(e) => {
            setSearchItem(e.target.value);
          }}
          size="small"
          className="rounded-full"
        />
        <Box className="w-4/5 h-1/2 border border-black shadow-md overflow-scroll">
          <Box className="h-full flex flex-col" width="max(max-content, 100%)">
            {getSearchItems()}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
