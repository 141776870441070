/* eslint-disable jsdoc/require-jsdoc */
import { PayloadAction } from '@reduxjs/toolkit';
import { CSDesktopState, FolderTree, desktopInitialState } from '../../slices';

/**
 * Desktop reducer builder
 */
export function desktopReducerBuilder() {
  return {
    desktopLogout: (state: CSDesktopState) => {
      Object.assign(state, desktopInitialState);
    },
    setIsUserLoggedIn: (state: CSDesktopState, action: PayloadAction<boolean>) => {
      state.isUserLoggedIn = action.payload;
    },
    setCurrentPage: (state: CSDesktopState, action: PayloadAction<number>) => {
      if (action.payload < 0) {
        state.currentPage = 0;
        console.warn('Current page cannot be less than 0, setting to 0');
        return;
      }
      state.currentPage = action.payload;
    },
    setUsername: (state: CSDesktopState, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    setFolders: (state: CSDesktopState, action: PayloadAction<FolderTree>) => {
      state.folders = action.payload;
    },
    setSelectedFolder: (state: CSDesktopState, action: PayloadAction<string>) => {
      state.selectedFolder = action.payload;
    },
    setEmail: (state: CSDesktopState, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setAscendingSort: (state: CSDesktopState, action: PayloadAction<boolean>) => {
      state.ascendingSort = action.payload;
    },
    setContextedItem: (state: CSDesktopState, action: PayloadAction<string>) => {
      state.contextedItem = action.payload;
    },
  };
}
