import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { CSDesktopState } from '../../slices';
import { getEmailThunk } from '../../thunks/get/getEmailThunk';
import { errorParser } from '../errorParser';
import { updateEmailThunk } from '../../thunks/update/updateEmailThunk';

/**
 * Email cases for the builder
 *
 * @param builder The builder to add the cases to
 */
export default function emailCases(
  builder: ActionReducerMapBuilder<CSDesktopState>,
): ActionReducerMapBuilder<CSDesktopState> {
  builder
    // * Get Email * //
    .addCase(getEmailThunk.fulfilled, (state, action) => {
      const { status, token } = action.payload;
      if (status === 'Succeeded') {
        state.token = token;
      } else {
        errorParser(token, state);
      }
    })
    .addCase(getEmailThunk.rejected, (state) => {
      errorParser('', state);
    })
    // * Update Email * //
    .addCase(updateEmailThunk.fulfilled, (state, action) => {
      const { status, token } = action.payload;
      if (status === 'Succeeded') {
        state.token = token;
      } else {
        errorParser(token, state);
      }
    })
    .addCase(updateEmailThunk.rejected, (state) => {
      errorParser('', state);
    });

  return builder;
}
