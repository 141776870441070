import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiConstants } from '../../data/constants';

export interface IUser {
  mname: string;
  mpass: string;
}

export const validateUser = createAsyncThunk('CSDesktop/validateUser', async (user: IUser) => {
  const response = await fetch(apiConstants.apiUrl + '/validatebrowser', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  });
  const data = await response.json();
  return data;
});
