import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { CSDesktopState } from '../../slices';
import { updateAccountThunk } from '../../thunks/update/updateAccountThunk';
import { errorParser } from '../errorParser';
import { addAccountThunk } from '../../thunks/add/addAccountThunk';
import { deleteAccountThunk } from '../../thunks/delete/deleteAccountThunk';

/**
 * Account cases for the builder
 *
 * @param builder The builder to add the cases to
 */
export default function accountCases(
  builder: ActionReducerMapBuilder<CSDesktopState>,
): ActionReducerMapBuilder<CSDesktopState> {
  builder
    // * Add Account * //
    .addCase(addAccountThunk.fulfilled, (state, action) => {
      const { status, token } = action.payload;
      if (status === 'Succeeded') {
        state.token = token;
      } else {
        errorParser(token, state);
      }
    })
    .addCase(addAccountThunk.rejected, (state) => {
      errorParser('', state);
    })
    // * Delete Account * //
    .addCase(deleteAccountThunk.fulfilled, (state, action) => {
      const { status, token } = action.payload;
      if (status === 'Succeeded') {
        state.token = token;
      } else {
        errorParser(token, state);
      }
    })
    .addCase(deleteAccountThunk.rejected, (state) => {
      errorParser('', state);
    })
    // * Update Account * //
    .addCase(updateAccountThunk.fulfilled, (state, action) => {
      const { status, token } = action.payload;
      if (status === 'Succeeded') {
        state.token = token;
      } else {
        errorParser(token, state);
      }
    })
    .addCase(updateAccountThunk.rejected, (state) => {
      errorParser('', state);
    });

  return builder;
}
