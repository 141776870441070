import { Box } from '@mui/material';
import { LoadingScreen } from '../LoadingScreen';

/**
 * The items grid.
 *
 * @param props The properties of the items grid.
 * @param props.items The items to display.
 * @param props.itemsPerRow The number of items per row.
 * @param props.paddingAround The padding around the items.
 * @param props.paddingTop The padding top of the items.
 * @param props.sideBarWidth The width of the side bar.
 * @param props.isLoading Whether the items are loading.
 */
export function ItemsGrid(props: IItemsGridProps) {
  return (
    <Box
      display="grid"
      gridTemplateColumns={`repeat(${props.itemsPerRow}, minmax(75px, 1fr))`}
      gridTemplateRows="repeat(auto-fill, minmax(75px, 1fr))"
      sx={{
        overflowWrap: 'revert',
      }}
      paddingTop={props.paddingTop + 'px'}
      paddingLeft={props.paddingAround + props.sideBarWidth + 'px'}
      paddingRight={props.paddingAround + 'px'}
      paddingBottom={props.paddingAround + 'px'}
      gap="20px"
      className="overflow-y-auto overflow-x-hidden absolute top-0 left-0 w-full h-full justify-center grid"
    >
      {props.isLoading ? <LoadingScreen /> : props.items ? props.items : 'No files found'}
    </Box>
  );
}

interface IItemsGridProps {
  itemsPerRow: number;
  paddingTop: number;
  paddingAround: number;
  sideBarWidth: number;
  isLoading: boolean;
  items: JSX.Element[];
}
