import { Box } from '@mui/material';
import { useCSAlert } from '../../data/useCSAlert';

/**
 * The alert component.
 */
export function CSAlert() {
  const currentAlert = useCSAlert();

  const styles = {
    transition: 'all 0.5s ease',
    opacity: currentAlert?.alertString ? 1 : 0,
    transform: currentAlert?.alertString
      ? 'translateY(0) translateX(-50%)'
      : 'translateY(-150%) translateX(-50%)',
  };

  return (
    <Box
      zIndex={9999}
      sx={styles}
      className="bg-red-300 flex fixed justify-center items-center left-1/2 top-8 w-80 h-20 pointer-events-none rounded-lg shadow-lg border border-gray-900 p-4"
    >
      <h1 className="text-center">{currentAlert?.alertString}</h1>
    </Box>
  );
}
