import { EditAccountInput } from './EditAccountInput';
import { Box, Button } from '@mui/material';
import { useRef, useState } from 'react';
import { appConstants } from '../../../../../data/constants';
import { getUsername } from '../../../../../state/slices';
import { updateMasterAccountThunk } from '../../../../../state/thunks/update/updateMasterAccountThunk';
import { useAppDispatch, useAppSelector } from '../../../../../data/TypedRedux';
import { logoutUserThunk } from '../../../../../state/thunks/logoutUserThunk';
import { addCSAlert } from '../../../../../data/addCSAlert';

/**
 * The properties of the edit account menu.
 *
 * @param props The properties of the edit account menu.
 * @param props.closeSettings The function to close the settings menu.
 */
export function EditAccountMenu(props: IEditAccountMenuProps) {
  const newUserRef = useRef<HTMLInputElement>(null);
  const oldPassRef = useRef<HTMLInputElement>(null);
  const newPassRef = useRef<HTMLInputElement>(null);
  const retypePassRef = useRef<HTMLInputElement>(null);

  const [showPassword, setShowPassword] = useState({ old: false, new: false, retype: false });

  const username = useAppSelector(getUsername);

  const dispatch = useAppDispatch();

  /**
   * Handles the update of the master account.
   */
  const handleUpdate = () => {
    if (newUserRef.current && oldPassRef.current && newPassRef.current && retypePassRef.current) {
      const newUsername = newUserRef.current.value;
      const oldPassword = oldPassRef.current.value;
      const newPassword = newPassRef.current.value;
      const retypePassword = retypePassRef.current.value;
      if (
        newUsername.length !== 0 &&
        oldPassword.length !== 0 &&
        newPassword.length !== 0 &&
        retypePassword.length !== 0
      ) {
        if (
          !newUsername.includes(' ') &&
          !newPassword.includes(' ') &&
          newUsername.length >= appConstants.passwordMinLength &&
          newPassword.length >= appConstants.passwordMinLength &&
          newUsername.length <= appConstants.passwordMaxLength &&
          newPassword.length <= appConstants.passwordMaxLength
        ) {
          if (newPassword === retypePassword) {
            dispatch(
              updateMasterAccountThunk({
                old_user: {
                  mname: username,
                  mpass: oldPassword,
                },
                new_user: {
                  mname: newUsername,
                  mpass: newPassword,
                },
              }),
            )
              .unwrap()
              .then((result) => {
                if (result.status === 'Succeeded') {
                  addCSAlert('Successfully updated account.', 'success');
                  dispatch(logoutUserThunk());
                  setShowPassword({ old: false, new: false, retype: false });
                  props.closeSettings();
                }
              });
          } else {
            addCSAlert('Passwords do not match. Make sure your passwords match', 'warning');
          }
        } else {
          addCSAlert(
            'Invalid username or password. Please do not include spaces and make sure your username and password is at least 5 characters long and less than 26 characters long',
            'warning',
          );
        }
      } else {
        addCSAlert('Please fill in all fields', 'warning');
      }
    }
  };

  return (
    <Box className="h-full w-full flex flex-col justify-start items-center gap-4 overflow-y-auto">
      <Box className="text-center text-2xl mt-2" fontFamily={appConstants.toolbarFont}>
        Edit Account
      </Box>
      <Box className="text-center text-lg mt-2 font-bold">{username}</Box>
      <EditAccountInput
        {...{
          newPassRef,
          newUserRef,
          oldPassRef,
          retypePassRef,
          setShowPassword,
          showPassword,
          username,
        }}
      />
      <Button variant="contained" onClick={handleUpdate}>
        Update
      </Button>
    </Box>
  );
}

interface IEditAccountMenuProps {
  closeSettings: () => void;
}
