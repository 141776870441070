import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiConstants } from '../../../data/constants';

export interface IUpdateMasterAccount {
  old_user: {
    mname: string;
    mpass: string;
    email?: string;
  };
  new_user: {
    mname: string;
    mpass: string;
    email?: string;
  };
}

export const updateMasterAccountThunk = createAsyncThunk(
  'CSDesktop/updateMasterAccountThunk',
  async (props: IUpdateMasterAccount) => {
    const response = await fetch(apiConstants.apiUrl + '/updateuser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...props }),
    });
    const data = await response.json();
    return data;
  },
);
