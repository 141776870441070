export type TCSAlert = {
  alertString: string;
  type: AlertType;
};

const alertStack: TCSAlert[] = [];

type AlertType = 'error' | 'success' | 'warning' | 'info';

/**
 * Function to add a CS alert to the alert stack
 *
 * @param alertString The alert string to add
 */
export function addCSAlert(alertString: string, type: AlertType) {
  alertStack.push({ alertString, type });
  window.dispatchEvent(new Event('alertStackUpdated'));
  setTimeout(() => {
    alertStack.shift();
    window.dispatchEvent(new Event('alertStackUpdated'));
  }, 3000);
}

/**
 * Function to get the alert stack
 *
 * @returns The alert stack
 */
export function getAlertStack() {
  return alertStack;
}
