import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import {
  getFolders,
  getModalOpen,
  getSelectedFolder,
  setFolders,
  setModalOpen,
} from '../../../state/slices';
import { useAppDispatch, useAppSelector } from '../../../data/TypedRedux';
import { useState } from 'react';
import { addFolderThunk } from '../../../state/thunks/add/addFolderThunk';
import { addAccountThunk } from '../../../state/thunks/add/addAccountThunk';
import { appConstants } from '../../../data/constants';
import { addCSAlert } from '../../../data/addCSAlert';

/**
 * The creation modal.
 */
export function CreationModal() {
  const dispatch = useAppDispatch();

  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const token = localStorage.getItem('Token');

  const selectedFolder = useAppSelector(getSelectedFolder);
  const allFolders = useAppSelector(getFolders);
  const folderArray = selectedFolder ? allFolders[selectedFolder] : allFolders;

  const modalOpen = useAppSelector(getModalOpen);

  /**
   * Sends a request to the server to add a folder.
   */
  function addFolder() {
    if (token === null) {
      addCSAlert('Error, no token detected.', 'error');
      return;
    }
    if (!name.trim()) {
      addCSAlert('Please enter a name', 'warning');
      return;
    }
    if (selectedFolder) {
      if (!username.trim() || !password.trim()) {
        addCSAlert('Please enter a username and password', 'warning');
        return;
      }
      dispatch(
        addAccountThunk({
          token: token,
          username: username,
          password: password,
          accountid: name,
          folder: selectedFolder,
        }),
      )
        .unwrap()
        .then((result) => {
          if (result.status === 'Succeeded') {
            // * Weird typing error here, hence for the long and ugly type
            dispatch(
              setFolders({
                ...allFolders,
                [selectedFolder]: { ...folderArray, [name]: { username, password } } as Record<
                  string,
                  { username: string; password: string }
                >,
              }),
            );
            dispatch(setModalOpen(false));
          }
        });
    } else {
      dispatch(
        addFolderThunk({
          folderName: name,
          token: token,
        }),
      )
        .unwrap()
        .then((result) => {
          if (result.status === 'Succeeded') {
            // * Weird typing error here, hence for the long and ugly type
            dispatch(
              setFolders({ ...folderArray, [name]: {} } as Record<
                string,
                Record<string, { username: string; password: string }>
              >),
            );
            dispatch(setModalOpen(false));
          }
        });
    }
  }

  return (
    <Modal open={modalOpen} onClose={() => dispatch(setModalOpen(false))}>
      <Box
        className="
      absolute border border-black top-1/2 bg-purple-50
      shadow-2xl flex flex-col justify-between items-center w-96 h-96
      pb-16 rounded-md overflow-hidden left-1/2 -translate-x-1/2 -translate-y-1/2"
      >
        <Box
          className="
        bg-gray-400 w-full h-12 shadow-md relative
        flex justify-end items-center pr-2"
        >
          <Typography
            fontSize="1.5rem"
            className="absolute top-1 left-1/2 -translate-x-1/2"
            fontFamily={appConstants.toolbarFont}
          >
            {selectedFolder ? 'Add Account' : 'Add Folder'}
          </Typography>
        </Box>
        <TextField
          id="folder-name"
          label={`${selectedFolder ? 'Account' : 'Folder'} Name`}
          variant="filled"
          type="text"
          onChange={(e) => setName(e.target.value)}
          size="small"
          className="rounded-full"
        />
        {selectedFolder && (
          <>
            <TextField
              id="username"
              label="Username"
              variant="filled"
              type="text"
              onChange={(e) => setUsername(e.target.value)}
              size="small"
              className="rounded-full"
            />
            <TextField
              id="password"
              label="Password"
              variant="filled"
              type="text"
              onChange={(e) => setPassword(e.target.value)}
              size="small"
              className="rounded-full"
            />
          </>
        )}
        <Button variant="contained" color="primary" onClick={addFolder}>
          Create {selectedFolder ? 'Account' : 'Folder'}
        </Button>
      </Box>
    </Modal>
  );
}
