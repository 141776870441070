import { Box, Tab, Typography } from '@mui/material';
import { appConstants } from '../../../../data/constants';
import { changelog } from '../../../../data/changelog';

/**
 * The about page.
 */
export function AboutPage() {
  const currentVersion = changelog.slice(-1)[0];

  return (
    <Box className="h-full w-full flex flex-col justify-start items-center gap-4 overflow-x-auto p-4">
      <Typography fontSize="3rem" fontFamily={appConstants.toolbarFont}>
        About
      </Typography>
      <Typography fontSize="1.5rem" fontFamily={appConstants.toolbarFont}>
        Hi, my name is <span className="font-bold underline">Caden Garrett</span> and this is one of
        my projects. This is actually part of a larger project called{' '}
        <span className="italic">Cheddar Safe</span>, which is a password manager that is designed
        to be as <span className="font-bold">secure</span> as possible. This project is the desktop
        version of
        <span className="italic"> Cheddar Safe</span>. The desktop version is designed to be used on
        a desktop computer and not for mobile web browsers (although larger screens such as tablets
        can still function).
        <br />
        <br />
        The desktop version is also designed to be used with the mobile version of{' '}
        <span className="italic">Cheddar Safe</span>. The mobile version is designed to be used on
        multiple devices and is designed to sync with the desktop version. The mobile version is
        currently in development and will be released soon. The desktop version is currently in
        beta. I hope you enjoy using <span className="italic">Cheddar Safe</span> and find it to be
        useful.
      </Typography>
      <Typography fontSize="1rem" fontFamily={appConstants.toolbarFont} color="rgba(0,0,0, 0.5)">
        Current Version: {currentVersion.version} <Tab /> Released on: {currentVersion.date}
      </Typography>
      <Typography fontSize="1rem" fontFamily={appConstants.toolbarFont} color="rgba(0,0,0, 0.5)">
        &copy; CSG Developments | {new Date().getFullYear()}
      </Typography>
    </Box>
  );
}
